<template>
  <div class="navLeft">
    <div
      class="item"
      v-for="item in navList"
      :key="item.id"
      :class="item.pathArr.indexOf($route.name) >= 0 ? 'act' : ''"
      @click="itemClick(item)"
    >
      <svg-btn :icon-class="'icon-menu' + item.id" />
      {{ $t(item.pathName)}}
      
       <el-badge v-if="item.path == '/news'" class="item12" :value="$store.state.userMsgNub" :hidden="$store.state.userMsgNub == 0" :max="99"></el-badge>
       <el-badge v-if="item.path == '/myCollection' || item.path == '/collectionSet'" class="item12" :value="$store.state.collectNum" :hidden="$store.state.collectNum == 0" :max="99"></el-badge>
       <svg-btn v-if="item.id == 2" icon-class='icon_todyh' style="margin-left:auto;margin-right:14px" />

    </div>
    <!-- <div class="bugbox" @click="goFeedback">
      <svg-btn icon-class="icon-edit-bug" />
      <span>新版本 bug 反馈</span>
    </div> -->
    <div class="themeBox">
      <div
        v-for="item in themeList"
        class="one"
        :class="{ one2: $store.state.themeBtn == item }"
        @click="changeTheme(item)"
        :key="item"
      >
        <svg-btn :icon-class="'icon-switch-' + item" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCollectInfo,
} from "@/utils/apiList/home";
export default {
  data() {
    return {
      navList: [
        { id: 0, pathName: "common.vip", path: "/vip", pathArr: ["vip","welfare","activities"] },
        {
          id: 1,
          pathName: "common.Files",
          path:'/'+this.$store.state.fmPath,
          pathArr: [this.$store.state.fmPath, "account","share","recycle","history","help","askdetail",'s','fmDown','tNewdown','g'],
        },
        {
          id: 6,
          pathName: "common.Set",
          path: "/set",
          pathArr: ["set","setRecycle"],
        },
        {
          id: 3,
          pathName: "common.Collect",
          path: "/myCollection",
          pathArr: ["myCollection","collectionSet"],
        },
        // { id: 4, pathName: "消息", path: "/news", pathArr: ["news"] },
        { id: 5, pathName: "common.app", path: "/app", pathArr: ["app"] },
        // {
        //   id: 2,
        //   pathName: "订阅号",
        //   path: "/",
        //   pathArr: [],
        // },
      ],
      routeAct: 0,
      themeList: ["system", "light", "dark"],
    };
  },

  created() {},
  methods: {
    //获取收藏更新量
    getCollectNum(){
      getCollectInfo().then(res =>{
        if(res.status == 1){
          this.$store.commit("setAttr", {
              name: "collectNum",
              val: Number(res.data.total),
          });
          this.$store.commit("setAttr", {
              name: "gathersNum",
              val: Number(res.data.gather),
          });
        }
      })
    },
    changeTheme(item) {
      this.$store.commit("setAttr", {
        name: "themeBtn",
        val: item,
      });
      if (item == "light" || item == "dark") {
        this.$utils.changeTheme(item);
      } else {
        this.listenTheme(1);
      }
    },
    listenTheme(index = 0) {
      const darkModeMediaQuery = window.matchMedia(
        "(prefers-color-scheme: dark)"
      );
      if (this.$store.state.themeBtn == "system" && index == 1) {
        if (darkModeMediaQuery.matches) {
          this.$utils.changeTheme("dark");
        } else {
          this.$utils.changeTheme("light");
        }
      }
      const darkModeChanged = (e) => {
        // 当深色模式状态发生变化时，执行相应的操作
        if (this.$store.state.themeBtn == "system") {
          if (darkModeMediaQuery.matches) {
            this.$utils.changeTheme("dark");
          } else {
            this.$utils.changeTheme("light");
          }
        }
      };
      darkModeMediaQuery.addListener(darkModeChanged);
    },
    itemClick(item) {
      if(this.$route.path=='/drive' && item.path=='/drive'){
        this.$store.commit("getfiles", {
          name: "query",
          value: {
            folder_id: 0,
            folders: [{folder_id: 0, folder_name: "全部文件"}]
          },
        });
      }
      if(item.pathName != '订阅号'){
        let routerName = this.$router.history.current.name || ''
        const routerNameList = ['s','fmDown','tNewdown']
        if(routerNameList.includes('s') && (this.$store.state.statusNum == '60001' || this.$store.state.statusNum == '60004')){
          if (localStorage.getItem("rebate_model") == 1) { 
            this.$store.commit("setAttr", {
              name: "route_ids",
              val: ['A1','B33'],
            })
          } else if (localStorage.getItem("rebate_model") == 3) {
            this.$store.commit("setAttr", {
              name: "route_ids",
              val: ['A1','B32'],
            })
          } else {
            this.$store.commit("setAttr", {
              name: "route_ids",
              val: ['A1','B3'],
            })
          }
        }
        if (routerNameList.includes(routerName)) {
          this.$utils.routeModeReported('C10',this.$store.state.voucher);
        }
        if(!routerNameList.includes(routerName) && item.pathName == '会员' && routerName != 'vip'){
          this.$store.commit("setAttr", {
            name: "route_ids",
            val: ['A1'],
          })
          this.$utils.routeModeReported('B7',this.$store.state.voucher);
        }
        this.$router.push(item.path);
      } else {
        window.open(this.$glb.Jmdyh + `/popularhome?platfro=${localStorage.getItem('platfro')}`, "_blank")
        // window.open(this.$glb.Jmdyh + `/sub`, "_blank")
      }
    },
    goFeedback(){
      window.open('https://jinshuju.net/f/eykqQ2')
    }
  },
  mounted() {
    this.listenTheme();
    //获取收藏更新量
    if(this.$utils.getCookie(this.$glb.fmCookieName) !== null){
      this.getCollectNum()
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

export default {
  home: { // 首页模块
    label1: "English",
    FEEMOO: 'logo-fm', // logo
    Home: 'Home', // 首页
    Clouddrive: 'Drive', // 云盘
    VIP: 'Premium', //会员
    Login: 'Log in', //登录
    Enterthewebdisk: 'Enter', // 进入网盘
    Signup: 'Sign up', //立即注册
    Enter: 'Enter my FEEMOO could', // 进入我的飞猫盘
    Tenyearsinarow: 'Ten years in a row', //十年如一日
    Launched: 'Launched 10 years old brand iOS、Android、Win、Mac full platform client', //上线10年老品牌 iOS、Android、Win、Mac全平台客户端
    FastDownload: 'Download', // 极速下载
    OnlinePreview: 'Preview', // 在线预览
    Clouddecompression: 'Unzip', // 云端解压
    MultiPlatformSupport: 'Client', // 多平台支持
    Shareandearn: 'Earnings', // 分享赚收益
    Extreme1: 'Extreme speed experience：', // 极致速度体验：
    Extreme2: ' The FEEMOO could uses distributed storage technology Technique, dry megabit broadband download, support multi-threaded download, none Any large file can be downloaded locally with ease.', // 飞猫盘采用分布式存储技术，千兆宽带下载，支持多线程下载，无论多大文件都能轻松下载到本地。
    Easy1: 'Easy to watch audio and graphics：', // 音频图文轻松看： 
    Easy2: ' no need to download, instantly open online, ultra-high-definition audio and video shock your eyes and ears, online preview to see unlimited, come and experience the fun of your heart!', // 无需下载，即刻在线打开，超高清影音震撼你的视听，在线预览看不受限制，快来体验随心所欲的乐趣吧！
    Oneclick1: 'One-click decompression：', // 一键解压： 
    Oneclick2: 'Fast preview of the contents of the compressed package, one-click decompression, strong compatibility, support for a variety of formats online decompression.', // 极速预览压缩包内容、一键解压、兼容性强、支持多种格式在线解压。
    Support: 'Support rar、zip and other mainstream decompression file formats', // 支持rar、zip等主流解压文件格式
    Crossplatform1: 'Cross-platform cloud service：', // 跨平台云服务： 
    Crossplatform2: ' High-experience client supports Windows, Mac, Android, iOS and other operating systems and devices, allowing you to access and manage your files anytime, anywhere.', // 高体验客户端支持Windows、Mac、Android、iOS等多种操作系统和设备，让您随时随地访问和管理您的文件。
    Rebate1: 'Rebate Program：', // 返利计划： 
    Rebate2: ' Upload a file and share the link or collection for your friends to download and get a rebate on your download!', // 上传文件并分享链接或合集给好友下载，就能获得下载返利！
    participate1: 'To participate in the rebate program, you must first apply to become a FEEMOO Master at the ', // 参与返利计划需先在飞猫达人中心 
    participate2: 'FEEMOO Master Center.', // 申请成为飞猫达人。
    BoardTitle1: 'Full platform support',
    BoardText1: 'Full platform client for iOS, Android, Win and Mac',
    BoardTitle2: 'Transmission and sharing',
    BoardText2: 'Multi-platform: desktop, mobile, web, anytime To manage files.\nHigh-speed transmission: fast upload and download, improve work efficiency Rate.\nEasy sharing: Easily share files, one click to create a share Set.',
    BoardTitle3: 'Safe and stable',
    BoardText3: 'FEEMOO uses distributed storage technology, gigabit broadband Load, support multi-threaded download,Enterprise-class security services, storage more secure.',
    CloudMaster: 'Make money as a CloudMaster', // 成为CloudMaster赚钱
    Sharing: 'Enjoy the fun of sharing and easily earn your own cash', // 享受分享的乐趣，轻松赚取属于你的变现收益
    Label1: 'Download revenue', //下载量收益
    Text1: 'Share files to friends to download, according to the size of the file to download Volume rebate, distinguish the level of 10,000 downloads up to 1888 yuan, Double every Wednesday and Thursday.', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    Label2: 'Member rebate income', //下载量收益
    Text2: 'Share files to friends to download, according to the download user to open membership Proportionally divided, files do not expire, and pages are simpler Clean, suitable for large file sharing', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    Label3: 'Promo code rebate', //下载量收益
    Text3: 'Friends through your promo code to open membership to enjoy discounts, while You can get high rebates; The higher the talent level, the discount code The bigger the discount.', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    Label4: 'downline rebate', //下载量收益
    Text4: "When a friend signs up as CloudMaster through your invite link, you get a high rebate on your friend's revenue.", //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    More: 'See more', // 查看更多
    MorePrivilege: 'See more privileges', // 查看更多特权
    Privilege: 'Member user privileges', // 会员用户特权
    CardText0: 'Extreme download acceleration',
    CardText1: 'Up to 1000GB\nStorage space',
    CardText2: 'Up to 300 times a day\nDownload credit',
    CardText3: 'Convenient cloud transfer',
    CardText4: 'No advertising',
    CardText5: 'Online cloud decompression',
    CardText6: 'Online audio and video',
    CardText7: 'Deleted files will remain in the Recycle Bin for 60 days',
    estimation: 'Estimation', // 评论
    evaluate: 'evaluate', // 评价
    AndroidClient: 'Android Client', // Android客户端
    Download: 'Download', // 下载
    DownloadNow: 'Download', // 立即下载
    iOSClient: 'iOS Client', // iOS客户端
    WindowsDesktop: 'Windows Desktop', // Windows桌面端
    MacOSDesktop: 'MacOS Desktop', // Mac OS桌面端,
    ScanQRcodedownload: 'Scan QR code download', // 扫码下载
    FEEMOOLIMITED: 'FEEMOO LIMITED' // 备案信息
  },
  login: { // 登录模块
    Home: 'Home', // 首页
    Clouddrive: 'Drive', // 云盘
    VIP: 'Premium', //会员
    ScanQRcodetologin: 'Scan QR code to log in', // 扫码登录
    Pleaseuse1: 'Please use', // 请使用
    Pleaseuse2: 'FEEMOO', //  飞猫盘
    Pleaseuse3: 'APP', //  APP
    Pleaseuse4: 'to open the QR code scanning function', // 在页面右上角打开扫一扫
    DownloadAPP: 'Download APP', // 下载APP
    DownloadDesktop: 'Download Desktop', // 下载桌面端
    Login: 'Log in', // 登录
    Signup: 'Sign up', // 注册
    Pleaseenter: 'Enter account/email/phone number', // 请输入账号/邮箱号/手机号
    Pleasepassword: 'Enter your password', // 请输入密码
    Rememberpassword: 'Memorize passwords', // 记住密码
    Forgotpassword: 'Forgot password？', // 忘记密码？
    Moreways: 'More ways', // 更多方式
    Back: 'Back', // 返回
    Mobilephone: 'Phone number retrieval', // 手机号找回
    themobilephone: 'Fill in the phone number when registering an account', // 请填写注册账号时所填写手机号
    Pleaseentermobile: 'Enter phone number', // 请输入手机号
    Next: 'Next', // 下一步
    Mobilephonenumber1: 'The number deactivated？', // 手机号已停用？
    Mobilephonenumber2: 'Email retrieval', // 邮箱找回
    Verifymobile: 'Verify phone number', // 验证手机号  
    verificationminutes1: 'Enter the 6-digit verification sent to', // 请输入发送至+8618899990000的6位验证码，有效期3分钟
    verificationminutes2: ' Code, valid for 3 minutes', // 请输入发送至+8618899990000的6位验证码，有效期3分钟
    Retrieve: 'Retrieve', // 重新获取
    VerifyEmail: 'Verify Email', // 验证邮箱
    Retrievecaptcha: 'Retrieve captcha', // 重新获取验证码
    Emailretrieval: 'Email retrieval', // 邮箱找回
    Pleaseemail: 'Fill in the email address when registering an account', // 请填写注册账号时所填写邮箱
    Pleasemailbox: 'Enter mailbox number', // 请输入邮箱号
    Emailnot: 'Email not receiving？', // 邮箱无法接收？
    Phoneretrieval: 'Phone number retrieval', // 手机号找回
    Resetpassword: 'Reset password', // 重设密码
    Pleasewithpassword: 'Enter a new password, with at least 6 digits or letters.', // 请输入新密码，最少设置 6 位数字或字母
    Newpassword: 'New password', // 新密码
    Enternewpassword: 'Enter the new password again', // 再次输入新密码
    Gotologin: 'Go to log in', // 完成，去登录
    automatically1: 'The new phone number will be automatically registered，', // 新手机号将自动注册，
    automatically2: 'Read and agree', // 已详读并同意
    automatically3: ' User service agreement', // 《用户服务协议》
    Clicktorefresh: 'Click to refresh', // 点击刷新
    ViewSupported: 'View Supported Registered Email Numbers', // 查看支持注册的邮箱号
    Setupaccount: 'Set up an account', // 设置账号
    accountdigits: 'Set up an account (6-20 digits or letters)', // 请设置飞猫盘账号（6-20位数字或字母）
    Setpassword: 'Set up a password', // 设置密码
    Setloginpassword: 'Set a login password (at least 6 digits or letters)', // 请设置登录密码（至少6位数字或字母）
    Enterpassword: 'Enter your password again', // 请再次输入密码
    Confirm: 'Confirm', // 确定
    Wechatscan: 'Wechat scan QR code login/Sign up', // 微信扫码登录/注册
    TheFEEMOOCenter:'The FEEMOO Center',//飞猫达人中心
    GrantedPrivileges:'Will get access',//将获得以下权限
    permissionOne: 'Get your FEEMOO avatar, nickname',
    permissionTwo: 'Get your FEEMOO account',
    Files: 'Files', // 文件
    Set: 'Set', // 合集
    Collect:'Collect',//收藏
    app: 'Get App', // 客户端
  },
  vip: { // vip模块
    vip: 'Premium', // 开通会员
    intrtit0: 'Exclusive download credits',//专属下载额度
    intrcontent0: 'Up to 300 rides/day',//最高享300次/天
    intrtit1: 'Download Speed',
    intrcontent1: 'enjoy Topspeed',
    intrtit2: 'Ad-free experience',
    intrcontent2: 'Premium Pure Experience',
    intrtit3: 'More online features',
    intrconten3: '6 Feature',
    openVip: 'Open Premium service to enjoy privileges', // 立即开通会员服务享受特权
    limitedSeries: 'Limited Series', // 限量发售
    greatValue: 'Great Value!', // 超值爆款
    vipMax: 'Permanent',//不限时长会员
    vipIntr: 'Note: The privileges marked with * need to consume the download quota to use, when the remaining download quota is insufficient, you can consume the welfare points to use!',//注：标有*特权需消耗下载额度使用，剩余下载额度不足时，可消耗福利点使用
    welfare: 'Welfare Agency',// 福利社
    buy:'Buy',//开通
    buy1:'Upgrade',//升级
    Notloggedin: 'Not logged in', // 未登录
    Cloudequity: 'equity', // 权益
    allBenefits: 'All Equity',// 全部权益
    ViewAllBenefits: 'View All Benefits', // 查看全部权益
    PremiumEscalation: 'Premium escalation', // 补差价升级
    ActualPayment: 'Payment', // 实付
    OpenVIP: 'Open Premium', // 开通会员
    Useode: 'Use a voucher or promo code', // 使用代金券或优惠码
    offAlready: 'off already', // 已优惠
    Renewalfee: 'Renewal fee ¥', // 续费25元
    Discount:'Discount',//立减
  },
  cloud: { // 云盘模块
    CloudSpace: 'Storage', // 云盘空间
    todayDownloadNum: "Today's Download Limit", // 今日下载
    files: 'Files', // 文件
    share: 'Share', // 分享
    history: 'Download Record', // 历史
    recycle: 'Recycle Bin', // 回收站
    daren: 'FEEMOO Master',
    filePlaceholderTop: 'Search cloud drive files',
    upload: 'Upload',//上传文件
    newFloder: 'New Folder',//新建文件夹
    allFiles: 'All files',//全部文件
    upname1: 'File',//文件
    upname2: 'Folder',//文件夹
    all: 'All',//全部
    package: 'Package',//压缩包
    medium: 'Medium',//音视频
    document: 'Documents',//文档
    picture: 'Pictures',//图片
    fileName: 'File name',//文件名
    browseDown: 'Browse/Download',//浏览/下载
    size: 'Size',//大小
    creatTime: 'Creation time',//创建时间
    noMore: 'Nothing more.',//没有更多了
    dragFiles0: 'Drag and drop file',//拖拽文件到这里上传
    dragFiles1: 'Go here to upload',//拖拽文件到这里上传
    cannot4g: 'Lndividual file size cannot exceed 4G',//单个文件大小不能超过4G
    xieyinot: 'Strictly prohibit the uploading of illegal content',//严禁上传非法内容
    rightOpen: 'Open', // 打开
    rightDownload: 'Download', // 下载
    rightShare: 'Share', // 分享
    rightRename: 'Rename', // 重命名
    rightDelete: 'Delete', // 删除
    rightMoveto: 'Move', // 
    pcDownfile: 'Client Download', // 客户端下载
    notFile: 'No relevant files are available', // 暂无相关文件
    trySearchOther: 'Try searching for other keywords', // 试试搜索其他关键词
    unzip: 'Unzip',//解压
    noUpLoad: 'No upload task is available', // 暂无上传任务
    setFileDirectory: 'Set file directory', // 设置文件目录
    Addfile: 'Add file', // 添加文件
    Allstarted: 'All started', // 全部开始
    vipDownload:'Premium Topspeed',//会员极速下载中 
    Allpause: 'All pause', // 全部暂停
    AllCancel: 'All Cancel', // 全部取消
    getLoading: 'Loading', // 获取文件信息中
    Pauseupload: 'Pause upload', // 暂停上传
    Uploadfailed: 'Upload failed', // 上传失败
    chenUploaded: 'Uploaded', // 已上传
    to:'to',//至
    pcTit: 'New PC terminal, large file download more stable', // 全新PC端，大文件下载更稳定
    memberDownTit:'Premium Download channel',//会员极速下载通道Fast download
    touristDownTit: 'Normal download channel',//普通下载通道
    memberDownTitStr:'Fast download',//极速下载中
    touristDownTitStr: 'Download',//下载中
    instantSpeed: 'Speed up',//立即提速
    notRefresh:'Do not refresh the web page while the task is in progress',//有任务进行时请勿刷新网页
    Goexperience: 'Experience', // 前往体验
    Nodownloadrecord: 'No download record', // 暂无下载记录
    Nodecompressionrecord: 'No decompression record', // 暂无解压记录
    Queuing: 'Queuing', // 排队中
    DownloadSuccess: 'Download Success', // 下载成功
    DownloadFailure: 'Download Failure', // 下载失败
    Decompressionrecords: 'Decompression records are kept for 7 days and automatically deleted upon expiry', // 解压记录保存7天，到期后自动删除
    Deleterecord: 'Delete record', // 删除解压记录
    Unpacking: 'Unpacking', // 正在解压
    UnpackingFailed: 'Unpacking Failed', // 解压失败
    Extractedto: 'Extracted to', // 已解压到
  },
  welfare: {// 福利社
    myWelfarePoints: 'My Welfare Points',//我的福利点
    viewWelfarePoints: 'View Details',//查看明细
    getpoint: 'Gain Welfare Points',
    welfareIntr: 'Benefits Point can be downloaded for free or redeemed for Premium',
    watchVideotoApp: 'Go to APP',
    payPoint: 'Spend Welfare Points',
    todayTimes: 'copies left today',
    duihuan: 'Redeemed',
    yiduihuan: 'Finished',
    canUsePoints:'benefit points available',
    welfarePoint: 'welfare point',// 福利点
    welfareDown:"We're done for the day.",//今日已兑完
    welfarePrefix: '',
    welfareSuffix: ' copies remaining'
  },
  common: { // 公共
    cloud: 'Drive', // 云盘
    vip: 'Premium', // 会员,
    app: 'Get App', // 客户端
    title: 'FEEMOO Cloud', // 页签名称
    Files: 'Files', // 文件
    Set: 'Set', // 合集
    Collect:'Collect',//收藏
  },
  setPage:{
    Set: 'Set', // 合集
    SearchSet:'Search set', // 搜索合集
    AllSet:'All set', //全部合集
    SetName:'Set name', //合集名
    NumberEntries:'Number of entries',//项目数
    browse:'browse',//浏览
    Collect:'Collect',//收藏
    ShareTime:'Share time', // 分享时间
    View:'View', //查看
    Edit:'Edit', // 编辑
    Delete:'Delete', //删除
    NoCollection:'No collection',//暂无合集
    NoToCollection:'No related set',//暂无相关合集
    recyleNoCollec:'No collection for Recycle Bin',//回收站暂无合集
    TextOne:'Create a collection and view it here',//创建合集后可以在这里查看
    TextTwo:'Try searching for other keywords',//试试搜索其他关键词
  },
  Collection:{
    Set: 'Set', // 合集
    Files: 'Files', // 文件
    allFiles: 'All files',//全部文件
    SearchFavorites:'Search favorites',//搜索收藏文件
    CollectionTime:'Collection time',//收藏时间
    NoFavoritesFiles:'No favorite files', //暂无收藏
    NoFavoriteSet:'No favorite set',//暂无收藏合集
    TextOne:'Favorite files can be viewed here',//收藏文件后可以在这里查看
    Texttwo:'Favorite set can be viewed here',//收藏合集后可以在这里查看
    AllSet:'All set', //全部合集
    SetName:'Set name', //合集名
    SearchCollections:'Search for collections',//搜索收藏合集
    New:'New',//更新
    owningUser:'Owning user',//所属用户
    uncollect:'uncollect',//取消收藏
    View:'View', //查看
  },
  userinfo: { // 个人信息悬浮模块
    vip: 'Premium', // 开通会员
    Downloadquota: 'Quota', // 下载额度
    Downloadspeed: 'Speed', // 下载加速
    Cloudcapacity: 'Storage', // 云盘空间
    Cloudequity: 'Equity', // 云盘权益
    DownloadLimit: 'Download quota', // 今日下载额度
    awelfarepoint: 'Welfare point', // 福利点
    HelpCentre: 'Help', // 帮助中心
    AccountSettings: 'Settings', // 账号设置
    SignOut: 'Sign Out', // 退出登录
    Login: 'Log in', // 登录
    Signup: 'Sign up', // 注册
  },
  share: { // 分享模块
    newCollections: 'New Set', // 新建合集
    allShare: 'All Share', // 全部分享
    all: 'All', // 全部
    files: 'Files', // 文件
    collention: 'Set', // 合集
    sharePlaceholder: 'Search for shared files', // 搜索分享文件
    copyLink: 'Copy link', // 复制链接
    viewLink: 'Share/Claim code', // 查看分享/取件码
    editCollection: 'Edit set', // 编辑合集文件
    reName: 'Rename', // 重命名
    deleteCollection: 'Delete Set', // 删除合集
    cancelShare: 'Unshare', // 取消分享
    cancelDelete: 'Unshare/Delete set', // 取消分享/删除合集
    notShareFiles: 'No files to share',//暂无分享文件
    shareLook: 'After sharing can be viewed here',//分享后可以在这里查看
  },
  downLoadHistory: { // 下载历史模块
    openDownload: 'Open Record Download History',//开启记录下载历史
    openDownloaded: 'Enabled to record download history',//已开启记录下载历史
    notOpenDownload: 'Download history is not enabled',//暂未开启记录下载历史
    downLoadHistoryPlaceholder: 'Search download history', // 搜索下载历史
    openVipDownload: 'Premium privilege, can be opened to record the download history', //开通会员专享，可开启记录下载历史
    notDownload: 'No download history',//暂无下载历史
    notHistory:'No relevant records', // 暂无相关记录
    recordDownloaded: 'Record downloaded files', // 记录下载过的文件
    viewDownloadShareFiles: 'View downloaded shared files',// 查看已下载的分享文件
    openVip: 'Premium', // 开通会员
    lookDownloaded: 'Download', // 查看下载
    deleteDownloaded: 'Delete', // 删除下载
  },
  recycle: { // 回收站模块
    clearRecycle: 'Empty Recycle Bin', // 清空回收站
    recyclePlaceholder: 'Search Recycle Bin Files', // 搜索回收站文件
    effectiveTime: 'Effective time',//有效时间
    deleteTime: 'Deletion time',//删除时间
    restore: 'Restore',//还原
    sweep:'Sweep',//彻底删除
    noFiles:'No files in the Recycle Bin',//回收站暂无文件
    tipsPrefix: 'Recycle Bin contents are saved for ',
    tipsSuffix: ' days and deleted upon expiration'
  },
  news: { // 信息悬浮框模块
    All: 'All', // 全部
    SystemNotification: 'System', // 系统通知
    OfficialNews: 'Official', // 官方消息
    ReadAll: 'Read All', // 全部已读
    Nonews: 'No news', // 暂无消息
  },
  getapp: { // 下载客户端模块
    DownloadClient: 'Download The Client Now', // 立即下载客户端
    Experiencemore: 'Experience more new features in the cloud', // 体验更多云端新功能
    AndroidClient: 'Android Client', // Android客户端
    iOSClient: 'iOS Client', // iOS客户端
    WindowsDesktop: 'Windows Desktop', // Windows桌面端
    MacOSDesktop: 'MacOS Desktop', // Mac OS桌面端,
    DownloadNow: 'Download', // 立即下载
    ScanQRcodedownload: 'Scan QR code download', // 扫码下载
  },
  downPage: { // 下载页面模块
    createTime: 'Creation date', // 创建日期
    fileSize:'File size', // 文件大小
    download: 'Download', // 下载
    saveFile: 'Transfer to FEEMOO Cloud',//转存至云盘
    allFiles: 'All files',//全部文件
    intrOne0: 'Have the resources and want to make a profit? Are other platforms earning too little?',
    intrOne1: 'To FEEMOO CLOUD upload files and share links or collections for friends to download, you can get high rebates!',
    intrOne2: 'A variety of sharing modes, multiple rebate ways, let you earn income every day!',
    intrTwo0: ' No more waiting for big files to download,',
    intrTwo1: 'Daily forwarding resources, courseware materials, office software, with the use of;',
    intrTwo2: 'Daily free download/transfer files, large files one key storage FEEMOO CLOUD',
    intrThree0: 'Online decompression, no need to download easily open the file;',
    intrThree1: 'support background decompression, queue decompression, open the zip package more convenient.',
    intrFour0: 'Preview a wide range of audio, video, image and zip files online;',
    intrFour1: 'No need to download, direct view!',
     experienceNow: 'Experience Now', // 立即体验
    installPC: 'Install and download the FEEMOO CLOUD client', // 安装下载飞猫盘客户端
    toTop:'Back to top to save the file', // 回到顶部保存文件
    report: 'Report',//举报
    vipFun: 'Premium Specific Functions',//会员专项功能
    pcDown: 'Download via PC client', // 通过PC客户端下载
    pcAppDown: 'Download via APP', // 通过APP下载
    webDown: 'Web end fast download', // 网页端 极速下载
    fmpPc: 'FEEMOO Cloud Client',//飞猫盘客户端
    touristDownFile: 'Free Download',//非会员下载
    fileStatus: 'File status',//文件状态
    fileDelete: 'This file has been filtered by the system audit or deleted by the uploader and cannot be accessed',//此文件已被系统审核过滤或被上传者删除，无法访问
    reference:'Reference', // 参考资料
    question: 'If you have any questions, please visit', // 如有疑问可访问
    touristFun: 'Free Function', // 非会员功能
    webTouristDown: 'Web end free download',//网页端 非会员下载
    watchVideoDown: 'Watch video Download', // 看视频下载
    fileDownloadStatus: 'File Download Status', // 文件下载状态
    downloadComplete: 'Download Success', // 下载完成
    downloadError: 'Download Failure', // 下载失败
    downloadRetry: 'Retry', // 下载取消
    memberDownTitStr:'Fast download',//极速下载中
    touristDownTitStr: 'Download',//下载中
    timeOut: 'The file has expired',//该文件已过期
    downloadNow: 'immediately download fast', // 立即极速下载
    isprivateFile: 'Private files are temporarily inaccessible',//私有文件暂时无法访问
    descPrefix: 'File uploaded by netizen: ',
    descSuffix: ', does not represent the position of this site'
  },
  setup: { // 账号设置模块
    EditAvatar: 'Edit Avatar', // 编辑头像
    AccountNumber: 'Account Number', // 账号
    PetName: 'Pet Name', // 昵称
    ModifyPhone: 'Modify Phone Number', // 修改手机号
    PhoneNumber: 'Phone Number', // 手机号
    WeChat: 'WeChat', // 微信
    LoginPassword: 'Login Password', // 登录密码
    Setpasswords: 'Set passwords that contain numbers and letters and change them regularly', // 设置包含数字和字母的密码，并定期更换
    Edit: 'Edit', // 编辑
    Bound1: 'Bound', // 已绑定
    Bound2: 'Bound', // 绑定
    Bound3: 'Unbound', // 未绑定
  },
  Help: { // 帮助中心模块
    FindCustomer: 'Help', // 找客服
    Feedback: 'Records', // 反馈历史
    Protocol: 'Pact', // 协议信息
    Announcement: 'Bulletin', // 平台公告
    Burning: 'Burning Question', // 热门问题
    Search: 'Search by Channel', // 按频道自主查找
    Nofeedbackhistory: 'No feedback history', // 暂无反馈历史
  },
   agreement: { // 协议信息模块
    disclaimerAgreement:'Disclaimer Agreement   ', // 《免责协议》
    privacyPolicy: 'Privacy Policy', // 《飞猫盘隐私政策》
    userSharingAgreement: 'User sharing agreement', // 《飞猫盘用户分享服务协议》
    memberServiceAgreement: 'Member Service Agreement', // 《飞猫盘会员服务协议》
    memberServiceAgreement2: 'Member Service', // 《飞猫盘会员服务协议》
    intellectualPropertyStatement: 'Intellectual Property Statement', // 《知识产权声明》
    userServiceAgreement:'User service agreement', // 《飞猫盘用户服务协议》
    automaticRenewalServiceAgreement: 'Renewal Service Agreement', // 《飞猫盘会员自动续费服务协议》
    privacyPolicyforIOS: 'Privacy Policy for IOS', // 《飞猫盘隐私政策》-iOS版
    readAndAgree: 'Agree to ', // 阅读并同意
    and: ' and ', // 以及
    and1: ' and ', // 和
    disclosureOfContentReviewMechanism: ' Disclosure of content review mechanism , ', // 《飞猫盘内容审核机制公示》
    dispositionOfOffendingAccounts: ' Disposition of offending accounts , ',// 《对于违规账号的处置方式》
    helpCenter: 'FEEMOO Help center',//《飞猫盘帮助中心》
    downPageDisclosureOfContentReviewMechanism: 'Disclosure of content review mechanism',//《飞猫盘内容审核机制公示》
  }

};

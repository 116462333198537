<template>
  <div class="downPage">
    <div class="pageBox pageBoxheight">
      <!-- 文件过期 -->
      <OtherStatus v-show="is_overtime == 1" status="4" @oNherStatus="oNherStatusfN"></OtherStatus>

      <!-- 文件信息 -->
      <el-skeleton :loading="loading_table">
        <template slot="template">
          <div class="dp-fileinfo">
            <el-skeleton-item class="icon"></el-skeleton-item>
            <div class="info">
              <div style="height: 26px">
                <el-skeleton-item style="width: 620px"></el-skeleton-item>
              </div>
              <div style="height: 21px; margin-top: 6px">
                <el-skeleton-item style="width: 320px"></el-skeleton-item>
              </div>
            </div>
            <!-- <fm-button round size="medium">收藏</fm-button> -->
          </div>
        </template>
        <template>
          <div class="dp-fileinfo">
            <img class="icon" :src="fileInfo.ext_icon" />
            <div class="info">
              <div style="height: 26px">
                <div class="collectBox">
                  <!-- 文件名和后缀名 -->
                  <span class="name">
                    {{ fileInfo.name }}{{ fileInfo.extension }}
                  </span>
                  <!-- 收藏图标 -->
                  <svg-btn @click="collect" class="collect" :icon-class="collectShow == 1?'icon-collect-two':'icon-collect-one'"></svg-btn>
                </div>
              </div>
              <div style="margin-top: 6px">
                <span>{{ $t('downPage.createTime') }}：{{ fileInfo.intime }}</span>
                <span>{{ $t('downPage.fileSize') }}：{{ fileInfo.size }}</span>
                <span class="desc">{{ $t('downPage.descPrefix') + fileInfo.nickname + $t('downPage.descSuffix') }}</span>
                <!-- <span class="desc"
                    > {{ $store.state.language == 'en' ? `File uploaded by netizen: ${
                      fileInfo.username
                    }, does not represent the position of this site` :`本文件由网友：${
                      fileInfo.username
                    }
                    自行上传，并不代表本站立场` }}</span
                  > -->
                  <span class="report" @click="goReport">{{ $t('downPage.report') }}</span>
              </div>
            </div>
            <!-- <fm-button round size="medium" @click="hCollect">{{ isCollect ? '已收藏' : '收 藏' }}</fm-button> -->
          </div>
        </template>
      </el-skeleton>
      <!-- 会员部分 -->
      <!-- <span class="title">开通会员</span> -->
      <!-- <VipBenefits style="max-width: 1700px" ref="VipBenefits"></VipBenefits> -->
      <PayOrderVip style="margin-top: 52px;margin-bottom: 56px;" @allQuan="PayJurisisShow = true"></PayOrderVip>
        <PayJuris
        :isShow="PayJurisisShow"
        @handleClose="PayJurisisShow = false"
        ></PayJuris>
      <!-- <span class="vipdesc"
        >{{$t('vip.vipIntr')}}</span
      > -->
      <!-- 福利社 -->
      <!-- <WelfareBox :pointNew="fileInfo.point"></WelfareBox> -->
      <WelfareLast ref="WelfareLast" :isShowBorder="true"></WelfareLast>

      <!-- 非会员功能 -->
      <div class="dp-use">
        <span class="title">{{ $t('downPage.touristFun') }}</span>
        <div class="box">
          <!-- <div class="card1" @click="electronFn">
            <img :src="$utils.getPng('web4/use_icon1')" />
            <span>通过PC客户端下载</span>
          </div>
          <div class="card1 multiLine" @click="handleFile(e, 3)">
            <div>
              <img :src="$utils.getPng('web4/use_icon2')" />
              <span>网页端 非会员下载</span>
            </div>
            <span class="limit" v-show="fileInfo.current_point != 0"
              >（{{ fileInfo.current_point }}福利点）</span
            >
          </div>
          <div class="card2 multiLine" @click="handleFile(e, 2)">
            <div>
              <img :src="$utils.getPng('web4/use_icon4')" />
              <span>转存至云盘</span>
            </div>
            <span class="limit" v-show="fileInfo.current_point != 0"
              >（{{ fileInfo.current_point }}福利点）</span
            >
          </div> -->
          <div class="btnTop1" @click="electronFn">
            <fm-button
              @click="newFloder"
              icon="icon-pc-icon"
              type="info"
              plain
              round
              size="medium"
              >{{ $t('downPage.pcDown') }}</fm-button
            >
          </div>
          <div class="btnTop1" @click="fileInfo.only_pc_down == 1 ? electronFn2() : handleFile(e, 3)">
            <fm-button icon="icon-web-icon" type="info" plain round size="medium"
              >{{ $t('downPage.webTouristDown') }}</fm-button
            >
          </div>
          <div class="btnTop1" v-if="fileInfo.video_ad_down_status == 1" @click="fileInfo.only_pc_down == 1 ? electronFn2() : watchVideoDownFn()">
            <fm-button icon="icon-Video-Down" type="info" plain round size="medium"
              >{{ $t('downPage.watchVideoDown') }}</fm-button
            >
          </div>
          <div class="btnTop1" @click="handleFile(e, 2)">
            <fm-button icon="icon-pagea3" round size="medium"
              >{{ $t('downPage.saveFile') }}</fm-button
            >
          </div>
        </div>
      </div>
      <!-- 转存成功 -->
      <SuccessBox
      ref="saveSuccDia"
        :successBoxMsg="successBoxMsg"
        :isShow="showSuccessBox"
        @handleClose="showSuccessBox = false"
      ></SuccessBox>
      <!-- 3元一天 -->
      <LimitedTimeVip
        ref="showLimitedTime"
        :isShowModel="showLimitedTime"
        :activity_vip_id="activity_vip_id"
        @handleClose="showLimitedTime = false"
        @openSuccess="openSuccess"
      ></LimitedTimeVip>
      <div
        v-if="pay_activities == 1 && !showLimitedTime && !showSuccessfullyModel"
        class="threeBtn"
        @click="threeBtnFn"
      >
        <img :src="$utils.getPng('threeBtn')" alt="" />
        <div class="threeyin"></div>
      </div>
      <!-- <div @click="gopageA" class="aa">click</div> -->
      <!-- 消耗弹框 -->
      <ConsumeBox
        ref="ConsumeBoxRef"
        @confirm="confirmHandle"
        @routeMode="routeModeFn"
        :isShow="showConsumeBox"
        :consumeInfo="consumeInfo"
        :showContent="showContent"
        :downisturnSaveFile="isturnSaveFile"
        @handleClose="showConsumeBox = false"
      >
      </ConsumeBox>
      <!-- type 1：会员  2：转存-->
      <PromoteDialog
        @handleClose="isShowPromote = false"
        @handleClose2="sHenJi"
        :isShow="isShowPromote"
        :vip_update="vip_update"
        :msg="promoteMsg"
        :type="promoteType"
        :icon="icon"
      >
      </PromoteDialog>
      <!-- type 1：会员  2：转存-->
      <PromoteDialog2
        @handleClose="isShowPromote2 = false"
        @handleClose2="handleClose2"
        :isShow="isShowPromote2"
        :msg="promoteMsg2"
        :type="promoteType2"
        icon="icon_diamond"
      >
      </PromoteDialog2>
      <!-- 绑定手机1  实名认证2 验证码3-->
      <BindPhone
        :isShow="isShowBindPhone"
        :type="bindType"
        :time="time"
        @getPhoneCode="getPhoneCode"
        @bindPhone="bindPhone"
        @handleClose="isShowBindPhone = false"
      ></BindPhone>
      <!-- 客户端唤起 -->
      <Electronpop
        :code="fileInfo.fshort"
        :isElectron="isElectron"
        @Visible="isElectron = false"
      ></Electronpop>
      <!-- 客户端唤起直接下载 -->
      <Electronpop2
        :code="fileInfo.fshort"
        :isElectron="isElectron2"
        :isJiuCode="true"
        @Visible="isElectron2 = false"
      ></Electronpop2>
      <!-- 支付弹框 -->
      <PayOrder
        ref="PayOrder"
        :isShow="isShowPayOrder"
        :payData="payData"
        :showBankInfo="showBankInfo"
        :allMember="allMember"
        :superSlideData="superSlideData"
        :upvipinfo="upvipinfo"
        :routeIds="$store.state.route_ids.join('-')"
        :voucher="fileInfo.voucher"
        @clearCoupon="clearCoupon"
        @handleClosePayDia="handleClosePayDia"
        @openSuccess="openSuccess"
        @openSuccess2="openSuccess2Fn"
      ></PayOrder>
      <!-- 优惠码 -->
      <DiscountCodeBox
        ref="DiscountCodeBox"
        :isShow="showDiscountCode"
        @handleClose="handleCloseDis"
        @checkCoupe="couponValidate"
      ></DiscountCodeBox>
      <!-- 补差价升级说明 -->
      <Upgradeexplain
        ref="Upgradeexplain"
        :isShow="showUpgradeexplain"
        :upvipinfo="upvipinfo"
        @handleClose="handleCloseUpgrade"
      ></Upgradeexplain>
      <SuccessfullyModel
        :payToast="payToast"
        :isShowModel="showSuccessfullyModel"
        @successRefresh="successRefresh"
      ></SuccessfullyModel>
      <Riskverification
        ref="Riskver"
        @handleClose="handleCloseRisk"
        @riskveAgain="riskveAgain"
      ></Riskverification>

      <VerifyPhone :isShow="showVerifyPhone" :verifyData="verifyData"  @handleClose="showVerifyPhone = false" @goNext="goNext"></VerifyPhone>
      <VideoDown :isShow="showVideoDown" :showVideoDown2="showVideoDown2" @handleClose="showVideoDown = false" @refreshEmit="watchVideoDownFn(1)" @response="responseFn(e, 3)" @response2="response2Fn" :videoQrdata="videoQrdata"></VideoDown>
      <!-- 取消收藏提示框 -->
      <TitleDialog
        ref="TitleDialogRef"
        :is-show="cloCollectShow"
        :title="collectObj.title"
        :text="collectObj.text"
        @handleClose="onCollectClose"
        @handleConfirm="onCollectConfirm"
      >
      </TitleDialog>
    </div>
  </div>
</template>

<script>
import VipBenefits from "@/components/VipBenefits/index";
import WelfareBox from "@/components/WelfareBox/index";
import LimitedTimeVip from "@/components/DownPage/LimitedTimeVip";
import ConsumeBox from "@/components/DownPage/ConsumeBox";
import SuccessBox from "@/components/DownPage/SuccessBox";
import PromoteDialog from "@/components/Dialog/PromoteDialog";
import PromoteDialog2 from "@/components/Dialog/PromoteDialog2";
import OtherStatus from "@/components/DownPage/OtherStatus";
import BindPhone from "@/components/Cloud/BindPhone";
import Electronpop from "@/components/DownPage/Electronpop";
import Electronpop2 from "@/components/DownPage/Electronpop2";
import PayOrder from "@/components/PayOrder/index";
import DiscountCodeBox from "@/components/Member/DiscountCodeBox.vue";
import SuccessfullyModel from "@/components/Member/SuccessfullyModel.vue";
import Upgradeexplain from "@/components/Member/Upgradeexplain.vue";
import VIPPAYMIXIN from "@/mixin/vip.vue";
import Riskverification from "@/components/Dialog/Riskverification.vue";
import WelfareLast from "@/components/welfareLast/index.vue";
import VerifyPhone from "@/components/VerifyPhone/index.vue";
import PayOrderVip from "@/components/PayOrderVip/index";
import PayJuris from "@/components/PayJuris/index";
import VideoDown from "@/components/VideoDown/index";



import { getDownFileInfo, turnSaveFile, getAdDownloadQrcodeAPI ,addCollect,delCollect} from "@/utils/apiList/downpage";
import { bindPhoneSendCode, bindPhoneAPI } from "@/utils/apiList/account";
import { downloadFile } from "@/utils/apiList/cloud";
import { handleCollect } from "@/utils/api/utils";
import TitleDialog from "@/components/Dialog/TitleDialog.vue";

export default {
  components: {
    VipBenefits,
    WelfareBox,
    LimitedTimeVip,
    ConsumeBox,
    SuccessBox,
    PromoteDialog,
    PromoteDialog2,
    OtherStatus,
    BindPhone,
    Electronpop,
    Electronpop2,
    PayOrder,
    DiscountCodeBox,
    SuccessfullyModel,
    Riskverification,
    Upgradeexplain,
    WelfareLast,
    VerifyPhone,
    PayOrderVip,
    PayJuris,
    VideoDown,
    TitleDialog,
  },
  mixins: [VIPPAYMIXIN],
  data() {
    return {
      loading_table: true,
      showLimitedTime: false,
      code: "",
      fileInfo: {},
      showConsumeBox: false,
      consumeInfo: {}, //消耗信息
      showSuccessBox: false,
      isShowPromote: false,
      isShowPromote2: false,
      icon: "",
      promoteType: 3,
      promoteType2: 2,
      promoteMsg: "",
      promoteMsg2: "",
      checkPopupType: 1, //3普通下载 2 转存
      popup_type: "",
      showContent: 2,
      vipLimitInfo: {},
      is_overtime: "",
      isShowBindPhone: false, //
      bindType: 1, //
      timerA: null,
      time: 60,
      msgid: "",
      vip_update: "",
      successBoxMsg: "",
      cLoading: false,
      sc: "", //屏幕点击位置
      ms: "", //屏幕点击位置
      isElectron: false,
      prompt_activities: 0, //三元一天支付弹窗
      activity_vip_id: "1055", //三元一天会员Id
      pay_activities: 0, //三元一天右下角图片点击出现三元一天支付弹窗的按钮
      phone_item: {
        phone: "",
        pcode: "",
      },
      downConfirm: {},
      showRisk: -1, //0 是转存，1下载
      isturnSaveFile: 1 ,// 1是非会员下载 2是转存云盘
      showVerifyPhone: false,
      showVideoDown: false,
      verifyData: {
        pcode: "+86", //区号
        phone: "" //手机号码
      },
      bindPhoneNext: 0, //绑定之后 继续后续的流程打得标记
      resCaptcha_verify:'',
      isElectron2: false,
      iscode: '',
      PayJurisisShow: false,
      videoQrdata: {},
      responseType: 2,
      response_body: {},
      showVideoDown2: false,
      TimestampRandom: '',
      collectShow:0, // 判断收藏图标状态
      cloCollectShow:false, //取消收藏弹窗是否显示
      collectObj: { //取消收藏弹窗文字内容
        title: "提示",
        text: "确定取消收藏该文件吗？",
      },
    };
  },
  computed: {
    isCollect: function () {
      return Number(this.fileInfo.is_collect) === 1;
    },
  },
  created() {
    
    document.addEventListener('visibilitychange',this.handleVisiable)
    this.code = this.$route.params.code;
    this.getDownFileInfo();
    this.generateTimestampRandom()
  },
  watch:{
    showConsumeBox(val){
       //关闭弹窗清空accessid ，有福利点消耗弹窗 先出现风险验证弹窗在出现福利点消耗 出现福利点再次去请求接口的时候需要保留accessid 所以在福利点消耗弹窗关闭时清空accessid 再次出现风险验证
      if(!val){
        this.accessid = ''
      }
    }
  },
  destroyed() {
    document.removeEventListener("visibilitychange",this.handleVisiable);
  },
  methods: {
      //点击收藏图标
      collect: _.debounce(
      function () {
        if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
        if(this.collectShow == false){
          //如果此时是关闭状态
          addCollect({
            file_id:this.fileInfo.id,
          }).then(res =>{

            if(res.status == 1){
              this.collectShow = 1
              this.$toast("收藏文件成功", 1);
              // 为了体验，不及时更新页面
              // this.getDownFileInfo()
            }else if(res.status == 10020 || res.status == 5000){
            }else{
              this.$toast(res.msg, 2);
            }
          })
        }else{
          //如果此时是打开状态
          this.collectObj.text = '确定取消收藏该文件吗？'
          this.cloCollectShow = true;
        }
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //确定取消收藏弹窗
    onCollectConfirm(){
      delCollect({
        file_id:this.fileInfo.id,
      }).then(res =>{
        if(res.status == 1){
          this.collectShow = 0
          this.onCollectClose()
          // 为了体验，不及时更新页面
          // this.getDownFileInfo()
        }else{
          this.$toast(res.msg, 2);
        }
      })
    },
    //关闭取消收藏弹窗
    onCollectClose() {
      this.cloCollectShow = false;
    },
    handleVisiable(e){
      switch (e.target.visibilityState) {
        case 'visible':
           this.$store.commit("setAttr", {
             name: "route_ids",
             val: ['A1'],
           })
          this.$utils.routeModeReported('B2',this.fileInfo.voucher);
          break
        case 'hidden':
          break
      }
    },
    sHenJi(){
      this.$utils.routeModeReported('C6',this.fileInfo.voucher);
    },
    routeModeFn(item){
      if(item==1){
        this.$utils.routeModeReported('C14',this.fileInfo.voucher);
      } else if(item==2){
        this.$utils.routeModeReported('C5',this.fileInfo.voucher);
      }

    },
    handleClose2(type){
      if(type == 2) {
        this.$utils.routeModeReported('C12',this.fileInfo.voucher);
      } else if(type == 1){
        this.$utils.routeModeReported('C13',this.fileInfo.voucher);
      }
    },
    openSuccess2Fn(){
      if(this.is_overtime == 1){
        this.$utils.routeModeReported('C1',this.fileInfo.voucher);
      }
    },
    oNherStatusfN(){
      this.$utils.routeModeReported('C11',this.fileInfo.voucher);
    },
    async hCollect() {
      if (this.cLoading) return;
      this.cLoading = true;
      let act, id;
      if (this.isCollect) {
        act = "0";
        id = this.fileInfo.collect_id;
      } else {
        act = "1";
        id = this.fileInfo.id;
      }
      let res = await handleCollect({
        action: act,
        type: "file",
        id,
      });
      if (res.state) {
        this.fileInfo.is_collect = act;
      } else if (res.msg) {
        this.$toast(res.msg, 2);
      }
      this.cLoading = false;
    },
    //去登录页
    goLogin() {
      let path = this.$route.fullPath.toString().slice(1);
      this.$router.push({ path: "/login", query: { path: path } });
      return;
    },
    //获取文件信息
    getDownFileInfo() {
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      this.loading_table = true;
      getDownFileInfo({ url: this.code }).then((res) => {
        if (res.status == "1") {
          this.$utils.routeModeReported('B2',res.data.voucher);
          if (!res.data.has_down) {//会员身份进入b页面跳回A页面，根据后端返回的字段判断
            this.$router.push({
              path: "/s/" + res.data.fshort,
            });
          }
          this.fileInfo = res.data;
          this.collectShow = res.data.is_collect
          this.pay_activities = res.data.pay_activities;
          this.is_overtime = res.data.is_overtime;
          this.loading_table = false;
          this.$refs.WelfareLast.point_int = res.data.point;
        } else if (
          res.status == "60001" ||
          res.status == "50006" ||
          res.status == "60004"
        ) {
          // this.$toast(res.msg, 2);
          this.$router.push({
            path: "/s/" + res.data.fshort,
          });
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //举报
    goReport() {
      window.open("https://jinshuju.net/f/fKYT3q");
    },
    responseFn(e, type){
      this.responseType = 5
      this.handleFile(e, type)
    },
    response2Fn(item){
      this.response_body = item || {}
    },
    //去转存 下载
    handleFile(e, type) {
      this.$store.commit("setAttr", {
        name: "events",
        val: [],
      })
      e = e || window.event;
      if(e.clientX && e.clientY ){
        this.ms = e.clientX + "*" + e.clientY;
      }else{
        this.ms = "400*400"
      }
      this.sc = window.screen.width + "*" + window.screen.height;
      this.checkPopupType = type;
      this.checkPopupType == 3 ? this.downloadFile(0) : this.savefile(0);
    },
    //消耗确认
    confirmHandle() {
      this.checkPopupType == 3 ? this.downloadFile(1,this.accessid) : this.savefile(1,this.accessid);
    },
    //文件转存
    savefile:_.debounce(
      function (confirm, accessid = "") {
      this.savefileParams = confirm;
      this.accessid = accessid;
      this.savefileALY();
    },
      1000,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    savefileALY(captcha_verify) {
      return new Promise((resolve) => {
        let confirm = this.savefileParams;
        let params = {
          url: this.code,
          confirm: confirm,
          captcha_verify: captcha_verify || this.resCaptcha_verify || "",
          accessid: this.accessid,
          ms: this.ms,
          sc: this.sc,
          rebate_model_voucher:this.fileInfo.rebate_model_voucher,
          // folder_id:0 //扩展参数允许转存的目录，目前不用传
        };
        turnSaveFile(params).then((res) => {
          this.resCaptcha_verify = '';
          this.accessid = '';
          if (res.data.accessid) { 
            this.accessid = res.data.accessid
          }
          if (res.data.captcha_verify) { 
            this.resCaptcha_verify = res.data.captcha_verify
          }
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.showConsumeBox = false;
            this.showSuccessBox = true;
            this.$refs.saveSuccDia.saveFileFolderId = res.data.folder_id;
            this.$refs.saveSuccDia.saveFileFolderName = res.data.folder_name;
            this.successBoxMsg = "转存成功";
            this.getDownFileInfo();
          } else if (res.status == "68001" || res.status == "68002") {
            resolve({
              result: true,
              validate: true,
            });
            this.showContent = 3;
            this.consumeInfo = res.data;
            this.showConsumeBox = true;
          } else if (res.status == "81001") {
            //福利点不足
            resolve({
              result: true,
              validate: true,
            });
            this.isturnSaveFile = 2;
            this.showContent = 2;
            this.consumeInfo = res.data;
            this.showConsumeBox = true;
          } else if (res.status == "40001") {
            //未绑定手机号
            resolve({
              result: true,
              validate: true,
            });
            this.bindType = 1;
            this.isShowBindPhone = true;
            this.bindPhoneNext = 1;
          } else if (res.status == "52001") {
            //空间已满
            resolve({
              result: true,
              validate: true,
            });
            this.icon = "icon_space";
            this.promoteMsg = res.data.content;
            this.vip_update = res.data.vip_update;
            this.promoteType = 3;
            this.isShowPromote = true;
          } else if (res.status == "62001") {
            //已转存过 (62001)
            resolve({
              result: true,
              validate: true,
            });
            this.showSuccessBox = true;
            this.successBoxMsg = res.msg;
          } else if (res.status == "42003") {
            //一元三天
            resolve({
              result: true,
              validate: true,
            });
            this.showLimitedTimeFun(true);
            this.$toast(res.msg, 4);
          } else if (res.status == "90001") {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.savefileALY);
          } else if (res.status == "90101") {
            resolve({
              result: true,
              validate: true,
            });
            this.showRisk = 0;
            this.$refs.Riskver.open();
          } else if (res.status == "60013") {
            this.promoteMsg2 = '开通会员立即转存文件'
            this.promoteType2 = 2
            this.isShowPromote2 = true;

          }else if (res.status == "83001") { 
            this.gopageA()
          } else if (res.status == "90200") { 
            this.showVerifyPhone = true;
            this.verifyData.pcode = res.data.pcode
            this.verifyData.phone = res.data.phone
          }else {
            resolve({
              result: true,
              validate: false,
            });
            //60013 文件已过期" 50006 锁定
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    //普通下载
    downloadFile(confirm, accessid = "") {
      this.downConfirm = confirm;
      this.accessid = accessid;
      this.downloadFileALY();
    },
    goNext() {
     this.showConsumeBox = false;
     this.$refs.ConsumeBoxRef.btnDis = true;
     this.checkPopupType == 3 ? this.downloadFile(1) : this.savefile(1);
    },
    downloadFileALY: _.debounce(
        function (captcha_verify) {
            return new Promise((resolve) => {
              let confirm = this.downConfirm;
              
              let params = {
                type: this.responseType == 5 ? 5 : 2, // 1:列表下载，2:福利点下载，3:vip下载，4：重试, 5: 扫码看视频下载
                url: this.code,
                confirm: confirm,
                ms: this.ms,
                sc: this.sc,
                captcha_verify: captcha_verify || this.resCaptcha_verify || "",
                accessid: this.accessid,
                rebate_model_voucher:this.fileInfo.rebate_model_voucher,
                qrcode_cd: this.responseType == 5 ? this.videoQrdata.video_ad_qrcode.cd || '' : '',
                ...this.response_body
              };
              downloadFile(params).then((res) => {
                this.resCaptcha_verify = '';
                this.accessid = '';
                if (res.data.accessid) { 
                  this.accessid = res.data.accessid
                }
                if (res.data.captcha_verify) { 
                  this.resCaptcha_verify = res.data.captcha_verify
                }
                this.responseType = 2
                this.response_body = {}
                if (res.status == "1") {
                  resolve({
                    result: true,
                    validate: true,
                  });
                  if(this.fileInfo.is_record_error_msg=='1'){//是否录制视频上报
                    this.$store.commit('onStartRecord')
                  }
                  let fileName = `${res.data.file.name}${res.data.file.extension}`;
                  let obj = {
                    fid: res.data.file.id,
                    url: res.data.url,
                    fileName: fileName,
                    fileSize: res.data.file.size, //文件大小
                    fileType: res.data.file.extension,
                    ext_icon: res.data.file.ext_icon,
                    title: this.$t('downPage.touristDownTitStr'),
                    down_id: res.data.down_id, //下载id，重试时需要用到
                    isClickFlag: res.data.is_up,
                    down_label: res.data.down_label,
                    is_vip_down:res.data.is_vip_down,
                    down_token: res.data.down_token,
                    is_remote_record: res.data.is_remote_record || '',
                  };
                  this.$store.commit("setAttr", { name: "downloadFile", val: obj });
                  this.$router.push({
                    path: "/tNewDown/" + res.data.jump_url,
                  });
                } else if (res.status == "40001") {
                  resolve({
                    result: true,
                    validate: true,
                  });
                  //未绑定手机号
                  this.bindType = 1;
                  this.isShowBindPhone = true;
                  this.bindPhoneNext = 2;
                } else if (res.status == "51001") {
                  resolve({
                    result: true,
                    validate: true,
                  });
                  //额度用尽
                  this.showContent = 1;
                  this.consumeInfo = res.data;
                  this.showConsumeBox = true;
                } else if (res.status == "81001") {
                  resolve({
                    result: true,
                    validate: true,
                  });
                  //剩余福利点不足
                  this.isturnSaveFile = 1;
                  this.showContent = 2;
                  this.consumeInfo = res.data;
                  this.showConsumeBox = true;
                } else if (res.status == "68001") {
                  resolve({
                    result: true,
                    validate: true,
                  });
                  //福利点下载弹
                  this.showContent = 3;
                  this.consumeInfo = res.data;
                  this.showConsumeBox = true;
                } else if (res.status == "42003") {
                  resolve({
                    result: true,
                    validate: true,
                  });
                  //三元一天弹
                  this.showLimitedTimeFun(true);
                  this.$toast(res.msg, 4);
                } else if (res.status == "63100") {
                  resolve({
                    result: true,
                    validate: true,
                  });
                  this.promoteMsg = res.msg;
                  this.promoteType = 2;
                  this.vip_update = "1";
                  this.icon = "icon_diamond";
                  this.isShowPromote = true;
                } else if (res.status == "90001") {
                  resolve({
                    result: false,
                    validate: false,
                  });
                  this.$toast(res.msg, 2);
                  this.$AliCode.handleOpen(this.downloadFileALY);
                } else if (res.status == "90101") {
                  resolve({
                    result: true,
                    validate: true,
                  });
                  this.showRisk = 1;
                  this.$refs.Riskver.open();
                } else if (res.status == "60013") {
                  this.promoteMsg2 = '开通会员立即极速下载'
                  this.promoteType2 = 1
                  this.isShowPromote2 = true;
                } else if (res.status == "83001") { 
                    this.gopageA()
                } else if (res.status == "90200") { 
                    this.showVerifyPhone = true;
                    this.verifyData.pcode = res.data.pcode
                    this.verifyData.phone = res.data.phone
                } else {
                  //60001无法下载，文件不存在或已被删除 //50006文件已被锁定,暂时无法访问
                  this.$toast(res.msg, 2);
                }
              });
            });
        },
        1000,
        {
            leading: true, //指定在延迟开始前调用
            trailing: false, //指定在延迟结束后调用
        }
    ),
    //发送验证码
    getPhoneAly(captcha_verify) {
      return new Promise((resolve) => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || "",
        }).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 1);
            this.msgid = res.data.msgid;
            this.bindType = 3;
            this.timerA = setInterval(() => {
              this.minute();
              this.$refs.bindPhoneCom?.onFocus();
            }, 1000);
          } else if (res.status == 90001) {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);

            this.$AliCode.handleOpen(this.getPhoneAly);
          } else {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    //发送验证码
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      };
      // var reg_tel =
      //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      // if (!reg_tel.test(phone)) {
      //     this.$toast("请正确输入手机号", 3);
      //     return true
      // }
      this.getPhoneAly();
    },
    minute() {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    //绑定手机号
    bindPhone(data) {
      let params = { msgid: this.msgid, ...data };
      bindPhoneAPI(params).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$toast(res.msg, 1);
          this.$store.commit("getuserVuex");
          if (this.bindPhoneNext == 0) return;
          this.bindPhoneNext == 2 ?this.downloadFile(0) : this.savefile(0);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    electronFn() {
      this.isElectron = true;
    },
    electronFn2(){ // 提示去客户端下载弹窗
      this.isElectron2 = true;
    },
    watchVideoDownFn(type){
      if(this.$store.state.userInfo.phone == ''){
        this.bindType = 1;
        this.isShowBindPhone = true;
        return
      }
      
      let e = window.event;
      this.ms = e.clientX + "*" + e.clientY;
      this.sc = window.screen.width + "*" + window.screen.height;
      this.showVideoDown2 = false
      this.getAdDownloadQrcodeFn(type)
    },
    getAdDownloadQrcodeFn(type){
      getAdDownloadQrcodeAPI({
        code: this.fileInfo.fshort,
        req_uuid: this.TimestampRandom,
        ms: this.ms,
        sc: this.sc,
      }).then((res)=>{
        if(res.status == 1){
          if(type == 1) {
            this.videoQrdata = res.data
            this.showVideoDown2 = true
          } else {
            this.videoQrdata = res.data
            this.showVideoDown = true
          }
          
        }
        
      })

    },
    generateTimestampRandom() {
      // 获取当前时间戳（以秒为单位）
      const timestamp = Math.floor(Date.now() / 1000);
      // 生成一个随机数（例如范围在0到100之间）
      const randomNumber = Math.floor(Math.random() * 101);
      // 结合时间戳和随机数
      this.TimestampRandom = `${timestamp}_${randomNumber}`;
    },
    refreshPage() {
      this.$store.commit("getuserVuex");
    },
    gopageA() {
      if (this.fileInfo.if_check_pucode == "1") {
        if (this.fileInfo.auto_set == "1") {
          this.$router.push({
            path:
              "/s/" +
              this.fileInfo.fshort +
              "?pucode=" +
              this.fileInfo.go_url +
              "#取件码：" +
              this.fileInfo.go_url,
          });
        } else {
          this.$router.push({
            path:
              "/s/" + this.fileInfo.fshort + "#取件码：" + this.fileInfo.go_url,
          });
        }
      } else {
        this.$router.push({
          path: "/s/" + this.fileInfo.fshort,
        });
      }
    },
    showLimitedTimeFun(newval) {
      // const myElement = this.$refs.showLimitedTime;
      this.showLimitedTime = newval;
    },
    threeBtnFn() {
      this.showLimitedTimeFun(true);
    },
    riskveAgain(accessid) {
      if (this.showRisk == 1) {
        this.downloadFile(this.downConfirm, accessid);
      } else {
        this.savefile(this.savefileParams, accessid);
      }
    },
    handleCloseRisk() {
      this.showConsumeBox = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

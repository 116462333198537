<template>
  <div class="fc-top">
    <div class="tabList">
      <span
        class="tabItem"
        :class="{ curStyle: item.id == curId }"
        v-for="(item, index) in tabList"
        :key="index"
        @click="setNav(item)"
        >{{ $t(item.name) }}</span
      >
    </div>
    <div class="search-box">
      <!-- <el-tooltip
        class="item"
        popper-class="fileTooltip"
        effect="dark"
        placement="bottom-start"
      >
        <div class="tips" slot="content">
          飞猫盘仅提供网络空间租赁服务，不参与内容贩卖、二次加工及推荐服务，依据国家相关法律及地方法规：我们将进行身份信息采集并在不侵犯用户隐私权的同时进行必要的机器审核。如您登记信息并上传文件即视为您已认可此要求。严禁上传、分享所有形式的非法内容。
          <a
            target="__blank"
            href="https://help.feimaoyun.com/archives/733.html"
            >《对于违规账号的处置方式》</a
          >
          <a
            target="__blank"
            href="https://help.feimaoyun.com/archives/1159.html"
            >《飞猫盘文件审核机制公示》</a
          >
        </div>
        <img class="icon" :src="$utils.getPng('web4/icon-circle-fill')" />
      </el-tooltip> -->
      <el-input
        :disabled="disabled"
        v-model="keyword"
        :placeholder="placeholder"
        @keyup.enter.native="toSearch"
      >
        <svg-btn
          v-show="keyword != ''"
          @click="clearValue()"
          slot="suffix"
          icon-class="icon-ipt-clear"
        ></svg-btn>
        <svg-btn
          @click="search()"
          slot="suffix"
          icon-class="icon-ipt-search"
        ></svg-btn>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    curId: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabList: [
        { id: 0, name: "cloud.files", url: this.$store.state.fmPath },
        { id: 1, name: "cloud.share", url: "/share" },
        { id: 2, name: "cloud.history", url: "/history" },
        { id: 3, name: "cloud.recycle", url: "/recycle" },
        { id: 4, name: "CloudMaster", url: "" },
      ],
      keyword: "",
    };
  },
  created() {},
  methods: {
    setNav(item) {
      if (item.id == 4) {
        let _url = "https://wwwtest.cloudmaster.fm/feimaopan";

        if (localStorage.getItem("platfro")) {
          _url += "?" + "platfro=" + localStorage.getItem("platfro");
        }
        window.open(_url,'_blank');
        return;
      }
      this.$router.push({
        path: item.url,
      });
    },
    search() {
      //if (this.keyword == "") {
      //  return;
      //}
      this.$emit("searchList", this.keyword);
    },
    clearValue() {
      this.keyword = "";
      this.$emit("searchList", this.keyword);
    },
    toSearch() {
      this.$emit("searchList", this.keyword);
    },
  },
};
</script>

<template>
  <div class="fmCloud" @mousemove.prevent="selectItems">
    <div class="fixed-top" :style="`width:${$store.state.appRightWidth}px`">
      <TabList
        ref="tabList"
        curId="2"
        :placeholder="$t('downLoadHistory.downLoadHistoryPlaceholder')"
        :disabled="is_svip == 0 ? 1 : 0"
        @setNav="setNav"
        @searchList="searchList"
      ></TabList>
      <el-skeleton :loading="false">
        <template>
          <div class="fc-second" v-show="is_svip == 1">
            <div class="add-box add-box1">
              <div class="btn-add" >
                <fm-button
                  icon="icon_openhis"
                  round
                  size="medium"
                  class="btnOpenHis"
                  @click="setDownLogSwitch(false)"
                  v-if="is_downlog == 1"
                  >{{ $t('downLoadHistory.openDownloaded') }}</fm-button
                >
                <fm-button
                  icon="icon_openhis1"
                  round
                  size="medium"
                  v-if="is_downlog == 0"

                  @click="setDownLogSwitch(true)"
                  >{{ $t('downLoadHistory.openDownload') }}</fm-button
                >
              </div>
            </div>
          </div>
          <div class="file-type" v-show="is_svip == 1 && is_downlog == 1">
            <div class="search-result" v-if="params.keywords == ''">
              <el-tooltip
                class="item r-tooltip"
                effect="dark"
                :content="$t('downLoadHistory.recordDownloaded')"
                placement="top"
              >
                <span class="all" style="font-weight: 500" @click="resetRouter"
                  >{{ $t('downLoadHistory.recordDownloaded') }}</span
                >
              </el-tooltip>
              <div class="total">
                {{ total>0 ? total : '0' }}
              </div>
            </div>
            <div class="search-result" v-if="params.keywords !== ''">
              <el-tooltip
                class="item r-tooltip"
                effect="dark"
                :content="$t('downLoadHistory.recordDownloaded')"
                placement="top"
              >
                <span class="all" @click="resetRouter">{{ $t('downLoadHistory.recordDownloaded') }}</span>
              </el-tooltip>
              <div class="r-search">
                <svg-btn icon-class="icon-file-direction" />
                <div class="r-search-tit">
                  {{ $utils.searchTitSlice(params.keywords) }}
                </div>
              </div>
            </div>
            <div class="fileClass">
              <div class="multiple-operation" v-if="checkedFiles.length > 0">
                <div @click="delFileAct()">
                  <svg-btn icon-class="icon_delete" />
                  <span style="min-width: 27px">{{ $t('downLoadHistory.deleteDownloaded') }}</span>
                </div>
              </div>
              <div class="screenBt">
                <svg-btn class="screen" icon-class="icon-cloud-screen" />
                <div class="floatBox">
                  <div class="title">添加筛选条件</div>
                  <div class="content">
                    <div
                      v-for="item in fileType"
                      @click="chageClass(item.id)"
                      :key="item.id"
                      class="box"
                    >
                    <svg-btn
                        class="icon_checks"
                        :icon-class="
                          item.id == fileTypeId 
                            ? 'icon-file-check'
                            : 'icon-file-uncheck-' + $store.state.theme"
                      ></svg-btn>
                      <span>{{ $t(item.name) }}</span>
                    </div>
                  </div>
                </div>
                <!-- 为了适配悬浮而创建的子类 -->
                <div class="lineTips"></div>
              </div>
              <div class="screenBt" style="margin-right: 4px">
                <svg-btn class="screen" icon-class="icon-option" />
                <div class="screenBox">
                  <div
                    @click="changeSort(item.id)"
                    v-for="item in screenList"
                    :key="item.id"
                    :class="{
                      curScreen: item.id == screenId || item.id1 == screenId
                    }"
                  >
                    <svg-btn icon-class="icon-cloud-check" />
                    <span>{{ item.name }}</span>
                  </div>
                </div>
                <!-- 为了适配悬浮而创建的子类 -->
                <div class="lineTips"></div>
              </div>
              <span class="line"></span>
              <fm-button
                class="icon-refresh"
                @click="refresh"
                icon="icon-refresh"
                type="info"
                text
                size="small"
              ></fm-button>
            </div>
          </div>
        </template>
      </el-skeleton>
    </div>
    <div class="history-content" v-if="is_svip == 1" v-loading="tableLoading">
      <div
        class="file-content"
        v-infinite-scroll="getList"
        ref="scrollBox"
        infinite-scroll-distance="50"
        infinite-scroll-immediate="false"
        @scroll="scrollBoxFun()"
        @mousedown="handleMouseDown($event, -1)"
        @mouseup.prevent="endSelection"
      >
        <div class="tab-th" v-show="total > 0">
          <div class="selectAll">
            <div @click="allCheck == 2 ? cancelCheckedVal() : setCheckedVal()">
              <svg-btn
                :icon-class="
                  allCheck == 2
                    ? 'icon-file-check'
                    : allCheck == 0
                    ? 'icon-file-uncheck-'+$store.state.theme
                    : 'icon-file-checks'
                "
              >
              </svg-btn>
            </div>
            <span>{{ $t('cloud.fileName') }}</span>
          </div>
          <div
            class="pointer"
            :class="['lineup2', { pointer: screenId == 0 }]"
            @click="changeSort(screenId == 2 ? 3 : 2)"
          >
            <span>{{ $t('cloud.size') }}</span>
            <img
              v-show="screenId == 2 || screenId == 3"
              :src="
                screenId == 2
                  ? $utils.getPng('web4/icon_order_descending')
                  : $utils.getPng('web4/icon_order_ascending')
              "
            />
          </div>
          <div
            class="pointer"
            :class="['lineup3', { pointer: screenId == 1 }]"
            @click="changeSort(screenId == 0 ? 1 : 0)"
          >
            <span>{{ $t('cloud.creatTime') }}</span>
            <img
              v-show="screenId == 0 || screenId == 1"
              :src="
                screenId == 0
                  ? $utils.getPng('web4/icon_order_descending')
                  : $utils.getPng('web4/icon_order_ascending')
              "
            />
          </div>
        </div>
        <div class="file-list" v-show="total > 0 && is_downlog == 1">
          <div
            class="file-item-hover"
            v-for="(item, index) in filesList"
            :key="index"
            :class="[
              { hoverItem: item.isHover || checkedFiles.indexOf(index) > -1 },
            ]"
            @mousedown="handleMouseDown($event, index)"
            @mouseenter="handleMouseenter($event, index)"
          >
            <a-dropdown
              :trigger="dropdown"
              @visibleChange="visibleChange"
            >
              <div class="file-item">
                <div class="lineup1">
                    <svg-btn
                      class="icon_checks"
                      @click.prevent="chooseFile(index)"
                      :icon-class="
                        checkedFiles.indexOf(index) > -1
                          ? 'icon-file-check'
                          : 'icon-file-uncheck-'+$store.state.theme
                      "
                    ></svg-btn>
                    
                <div class="openFoldername">
                  <img class="icon" :src="item.ext_icon" />
                  <el-tooltip open-delay="1000" popper-class="item list-tooltip" 
                  effect="dark" :content="`${item.name}${item.extension?item.extension :''}`" 
                  placement="bottom">
                    <span class="name">{{ item.name }}{{ item.extension }}</span>
                  </el-tooltip>
                  
                </div>
                </div>
                <div class="tools-box">
                  <div class="itemFilesTools" v-show="checkedFiles.length < 2">
                    <template>
                      <div class="act" @click="downloadFile(item)">
                        <el-tooltip open-delay="1000"
                          popper-class="item list-tooltip"
                          effect="dark"
                          content="查看下载"
                          placement="bottom"
                        >
                          <svg-btn icon-class="icon_down"></svg-btn>
                        </el-tooltip>
                      </div>
                      <div class="act">
                        <el-dropdown placement="bottom-start"
                          @visible-change="changeVisible($event, item)"
                          @command="(command) => handleCommand(command, item)"
                        >
                          <div>
                            <svg-btn icon-class="icon-cloud-more"></svg-btn>
                          </div>
                          <el-dropdown-menu
                            class="moreAct"
                            slot="dropdown"
                            :class="isOpen ? '' : 'isOpen1'"
                          >
                            <el-dropdown-item command="b"
                              >{{$t('cloud.rightDelete')}}</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="lineup2">{{item.size}}</div>
                <div class="lineup3">{{item.intime}}</div>
              </div>
              <template #overlay>
                <a-menu class="menu-a" :class="isOpen2 ? 'menu-b':''">
                  <a-menu-item
                    @click="rightHandle(menu_item.id, item)"
                    v-for="menu_item in isSelectMore ? list1 : list2"
                    :key="menu_item.id"
                    :class="menu_item.id == -1 ? 'line' : 'me-item'"
                    >{{ $t(menu_item.name) }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
          <div
            v-show="showFlag == 1"
            class="selection-box"
            :style="{
              left: selectionBox.left + 'px',
              top: selectionBox.top + 'px',
              width: selectionBox.width + 'px',
              height: selectionBox.height + 'px',
            }"
          ></div>
          <div class="isOver" v-if="loadingFlag" v-loading="loadingFlag"></div>
          <div class="isOver" v-if="isOver">{{ $t('cloud.noMore')  }}</div>
        </div>
        <div class="file-default" v-show="total == 0 && is_downlog == 1">
          <img :src="$utils.getPng('web4/file_default')" />
          <div class="search" v-if="params.keywords!=''">
            <span>{{ $t('downLoadHistory.notHistory') }}</span>
            <span>{{ $t('cloud.trySearchOther')  }}</span>
          </div>
          <div class="search" v-else>
            <span>{{ $t('downLoadHistory.notDownload') }}</span>
            <span>{{ $t('downLoadHistory.viewDownloadShareFiles') }}</span>
          </div>
        </div>
        <div class="file-default" v-show="is_downlog == 0">
          <img :src="$utils.getPng('web4/file_default')" />
          <div class="search">
            <span>{{ $t('downLoadHistory.notOpenDownload') }}</span><span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="open-vip" v-else>
      <div>
        <img :src="$utils.getPng('web4/icon_diamond')" />
        <fm-button size="medium" round @click="openVip">{{ $t('downLoadHistory.openVip') }}</fm-button>
        <span class="desc">{{ $t('downLoadHistory.openVipDownload') }}</span>
      </div>
    </div>
    <delHistoryFileTip
      :isShow="showDelFileTip"
      @handleClose="showDelFileTip = false"
      @doDel="historyFileDel"
    ></delHistoryFileTip>
    <div class="toTop-div" v-show="showTop" @click="scrollToTop">
      <svg-btn icon-class="icon-toTop" style="width: 24px;height: 24px;"></svg-btn>
    </div>
  </div>
</template>

<script>
import {
  setDownLogSwitch,
  historyFileList,
  historyFileDel,
} from "@/utils/apiList/cloud";
import TabList from "../TabList/index.vue";
import DelHistoryFileTip from "@/components/Cloud/DelHistoryFileTip";
export default {
  components: {
    TabList,
    DelHistoryFileTip,
  },
  data() {
    return {
      curId: 0,
      keyword: "",
      fileType: [
        { id: 0, name: "cloud.all" },
        { id: 1, name: "cloud.package" },
        { id: 2, name: "cloud.medium" },
        { id: 3, name: "cloud.document" },
        { id: 4, name: "cloud.picture" },
      ],
      fileTypeId: 0,
      screenList: [
        { id: 2, id1: 3, name: "按大小排序" },
        { id: 0, id1: 1, name: "按时间排序" },
      ],
      screenId: 0,
      filesList: [],
      total: -1,
      checkedFiles: [],
      params: {
        down: "", //排序
        keywords: "",
        page: 0,
        limit: 60,
        size: "",
        time: 1,
        type: 0,
      },
      allCheck: 0,
      is_svip: sessionStorage.getItem("is_svip_history"),
      is_downlog: sessionStorage.getItem("is_downlog"), //开关：1开启；0关闭
      showDelFileTip: false,
      handleItem: {},
      showTop: false,
      isOver: false,
      replacingData: false,
      loadingFlag: false,
      isOpen: false,
      isOpen2: true,
      isSelecting: 0,
      showFlag: 0,
      selectionBox: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      isSelectMore: false,
      list1: [{ id: 2,  name:'downLoadHistory.deleteDownloaded'}],
      list2: [
        { id: 0, name:'downLoadHistory.lookDownloaded'},
        { id: -1, name: "" },
        { id: 2, name:'downLoadHistory.deleteDownloaded'},
      ],
      tableLoading: true,
      dropdown: ['contextmenu']
    };
  },
  created() {
    this.getList(1);
    this.$utils.routeModeReported('B9');
  },
  mounted() {
    this.$store.commit("setAttr", {
      name: "endSelectFuncallback",
      val: this.endSelectFun,
    });
  },
  watch: {
    checkedFiles: {
      handler(newval, oldVal) {
        if (newval.length > 0 && newval.length < this.filesList.length) {
          this.allCheck = 1;
        } else if (newval.length == 0) {
          this.allCheck = 0;
        } else if (newval.length == this.filesList.length) {
          this.allCheck = 2;
        }
      },
      immediate: true,
    },
    pageValue(newVal) {
      if (newVal == 1) {
        this.cancelCheckedVal();
        this.scrollToTop();
      }
    },
  },
  computed: {
    pageValue() {
      return this.params.page;
    },
  },
  methods: {
    setNav(item) {
      this.$router.push({
        path: item.url,
      });
    },
    chageClass(id) {
      this.fileTypeId = id;
      this.params.type = id;
      this.checkedFiles = [];
      this.getList(1);
    },
    //搜索
    searchList(value) {
      this.params.keywords = value;
      this.checkedFiles = [];
      this.getList(1);
    },

    changeSort(id) {
      this.checkedFiles = [];
      this.screenId = id;
      switch (id) {
        case 0: //时间降序 下
          this.params.time = 1;
          this.params.size = "";
          this.params.down = "";
          break;
        case 1: //时间升序 上
          this.params.time = 0;
          this.params.size = "";
          this.params.down = "";
          break;
        case 2: //大小降序 下
          this.params.size = 1;
          this.params.down = "";
          this.params.time = "";
          break;
        case 3: //大小升序 上
          this.params.size = 0;
          this.params.down = "";
          this.params.time = "";
          break;
        case 4:
          this.params.down = 1;
          this.params.time = "";
          this.params.size = "";
          break;
        case 5:
          this.params.down = 0;
          this.params.time = "";
          this.params.size = "";
          break;
        default:
          break;
      }
      this.params.page = 0;
      this.getList(1);
    },
    getList: _.debounce(
      function (type = 0) {
        if (type == 1) {
          this.filesList = [];
          this.tableLoading = true;
          this.loadingFlag = false;
          this.params.page = 0;
        } else {
          if (this.isOver) return;
          this.tableLoading = false;
          this.loadingFlag = true;
        }
        this.params.page += 1;
        this.isOver = false;
        historyFileList(this.params)
          .then((res) => {
            if (res.status == "1") {
              this.is_downlog = res.data.is_downlog 
              sessionStorage.setItem(
                "is_downlog",
                res.data.is_downlog 
              );
              this.is_svip = res.data.is_svip;
              sessionStorage.setItem("is_svip_history", res.data.is_svip);
              this.total = res.data.total;
              this.loadingFlag = false;
              this.tableLoading = false;
              if (res.data.list.length < this.params.limit) this.isOver = true;

              res.data.list.forEach((item, index) => {
                item.isHover = false;
              });
              if (this.params.page == 1) {
                this.filesList = res.data.list;
              } else {
                this.filesList = this.filesList.concat(res.data.list);
              }
              if (
                this.checkedFiles.length > 0 &&
                this.checkedFiles.length < this.filesList.length
              ) {
                this.allCheck = 1;
              } else if (this.checkedFiles.length == 0) {
                this.allCheck = 0;
              } else if (this.checkedFiles.length == this.filesList.length) {
                this.allCheck = 2;
              }
              this.dropdown = ['contextmenu']
            } else {
              this.loading=false
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => {});
      },
      300,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    setFolderRouter() {},
    setDownLogSwitch(flag) {
      setDownLogSwitch({ history_switch: flag }).then((res) => {
        if (res.status == "1") {
            this.checkedFiles=[]
            this.getList(1);
            this.$toast(res.msg, 1);
        } else {
          this.is_downlog = 0;
          this.$toast(res.msg, 2);
        }
      });
    },
    // 单个点击选中
    chooseFile(index) {
      var exist = this.checkedFiles.indexOf(index);
      if (exist == -1) {
        this.checkedFiles.push(index);
      } else {
        this.checkedFiles.splice(exist, 1);
      }
    },
    //全选
    setCheckedVal() {
      this.checkedFiles = [];
      var tmpfiles = this.filesList;
      for (var i = 0, len = tmpfiles.length; i < len; i++) {
        this.checkedFiles.push(i);
      }
      this.allCheck = 1;
    },
    // 取消全选
    cancelCheckedVal() {
      this.allCheck = 0;
      this.checkedFiles = [];
    },
    openVip() {
      this.$utils.routeModeReported('C16');
      this.$router.push({
        path: "vip",
      });
    },
    delFileAct(item) {
      this.handleItem = item;
      this.showDelFileTip = true;
    },
    // 获取选中的文件的id
    getSelFileIds() {
      var ids = [];
      this.checkedFiles.map((item) => {
        ids.push(this.filesList[item].id);
      });
      return this.$utils.arrFilter(ids);
    },
    //删除
    historyFileDel() {
      var ids = "";
      if (this.handleItem) {
        ids = this.handleItem.id;
      } else {
        ids = this.getSelFileIds().toString();
      }
      historyFileDel({ ids: ids }).then((res) => {
        if (res.status == "1") {
          this.$toast(res.msg, 1);
          this.showDelFileTip = false;
          this.handleItem = {};
          this.checkedFiles = [];
          this.getList(1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    scrollBoxFun() {
      var scrollEl = this.$refs.scrollBox;
      this.showTop = scrollEl.scrollTop > 50;
      let list = document.getElementsByClassName("el-tooltip__popper");
      let list1 = document.getElementsByClassName("el-dropdown-menu");
      if (list.length > 0) {
        list[list.length - 1].style.display = "none";
      }
      if (list1.length > 0) {
        list1[list1.length - 1].style.display = "none";
      }
    },
    scrollToTop() {
      let scrollEl = this.$refs.scrollBox;
      if (scrollEl) {
        scrollEl.scrollTop = 0;
      }
    },
    //下载
    downloadFile(item) {
      window.open(item.link);
    },
    changeVisible(e, item) {
      item.isHover = e;
    },
    handleCommand(command, item) {
      if (command == "b") {
        this.delFileAct(item);
      } else {
        this.downloadFile(item);
      }
    },
    rightHandle(id, item) {
      this.visibleChange(false);
      if (id == 0) {
        this.downloadFile(item);
      } else {
        this.delFileAct();
      }
    },
    refresh() {
      this.params.page = 0;
      this.checkedFiles = [];
      this.getList(1);
    },
    handleMouseDown: _.debounce(
      function (event, index) {
        if(index == -1){
          this.checkedFiles = []
        }
        this.selectionBox = {
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        };

        this.startX = event.clientX;
        this.startY = event.clientY;

        const currentX = event.clientX;
        const currentY = event.clientY;
        // 检查触发事件的元素是否是 file-item-hover 的父元素
        if (event.button == 0) {
          // if (event.target.classList.contains("el-tooltip")) {
          //   this.checkedFiles = [];
          //   return;
          // }
          let arr = ['icon_checks','iconColor']
          if (!arr.includes(event.target.classList[0])) {
            //点击icon_checks 即左侧选中多选不触发handleMouseDown 只触发chooseFile
            // 执行你的 mousedown 逻辑
            this.selectItemOne(index);
          }
        } else if (event.button == 1) {
        } else if (event.button == 2) {
          if(index == -1) return;
          var exist = this.checkedFiles.indexOf(index);
          if (exist == -1) {
            this.checkedFiles = [];
            this.checkedFiles.push(index);
          } else {
            // this.checkedFiles.splice(exist, 1);
          }
          if (this.checkedFiles.length > 1) {
            this.isSelectMore = true;
          } else {
            this.isSelectMore = false;
          }
        }
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    handleMouseenter(event, index) {},
    chooseFile(index) {
      // 点击文件选中
      var exist = this.checkedFiles.indexOf(index);
      if (exist == -1) {
        this.checkedFiles.push(index);
      } else {
        this.checkedFiles.splice(exist, 1);
      }
    },

    selectItemOne(index) {
      if (index == -1) {
        this.isSelecting = 1;
        return;
      }
      // 点击文件选中
      var exist = this.checkedFiles.indexOf(index);
      if (exist == -1) {
        this.checkedFiles = [];
        this.checkedFiles.push(index);
        this.isSelecting = 1;
      } else {
        // this.checkedFiles.splice(exist, 1);
      }
    },
    selectItems: _.debounce(
      function (event) {
        const currentX = event.clientX;
        const currentY = event.clientY;

        // 计算选择框的位置和大小
        const minX = Math.min(this.startX, currentX);
        const minY = Math.min(this.startY, currentY);
        const maxX = Math.max(this.startX, currentX);
        const maxY = Math.max(this.startY, currentY);
        this.selectionBox = {
          left: minX,
          top: minY - 56,
          width: maxX - minX,
          height: maxY - minY,
        };
        if (this.isSelecting == 1) {
          // 遍历可选择项，判断是否在选择框内并选中
          this.filesList.forEach((item, index) => {
            const itemElement = document.querySelector(
              `.file-item-hover:nth-child(${this.filesList.indexOf(item) + 1})`
            );
            const itemRect = itemElement.getBoundingClientRect();
            if (itemRect.top > minY - 56 && itemRect.bottom < maxY + 69) {
              if (!this.checkedFiles.includes(index)) {
                this.checkedFiles.push(index);
              }
              this.showFlag = 1;
            } else {
              const index = this.checkedFiles.indexOf(index);
              if (index !== -1) {
                this.checkedFiles.splice(index, 1);
              }
            }
          });
        }
      },
      0,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    endSelection() {
      this.showFlag = 0;
    },
    endSelectFun() {
      this.isSelecting = 0;
      this.showFlag = 0;
      this.$store.commit("setAttr", {
        name: "showFlag",
        val: 0,
      });
      this.$store.commit("setAttr", {
        name: "check_str",
        val: "",
      });
    },
    resetRouter() {
      this.params = {
        down: "", //排序
        keywords: "",
        page: 0,
        limit: 60,
        size: "",
        time: 1,
        type: 0,
      };
      this.checkedFiles=[]
      this.fileTypeId=0
      this.$refs.tabList.keyword = "";
      this.getList(1);
    },
    visibleChange(e) {
      if(e){
        this.dropdown = ['contextmenu','click']
        this.isOpen = e;
        this.isOpen2 = true
      } else {
        this.isOpen2 = false
        this.dropdown = ['contextmenu']
      }
    },
  },
};
</script>

<style lang="scss"></style>

<template>
  <div id="homepage" ref="addScroll" @scroll="scrollBox"  v-scroll-outer="scrollBox">
    <div class="homepage">
      <div class="Navbar Navbar2" :style="{boxShadow: showNavbar2?'inset 0px -1px 0px 0px #F5F5F5':'none'}">
        <div class="top-menu">
          <svg-btn  @click="goRouter(0)" class="logo pointer" style="padding-right: 10px;"  icon-class="icon-logo0-new"></svg-btn>
          <svg-btn @click="goRouter(0)" class="logo1 pointer color1"
            :icon-class="$t('home.FEEMOO')"></svg-btn>
          <span class="nav-name" :class="{'nav-name2':index==3}" v-for="(item,index) in navData" :key="item" @click="goRouter(index)">{{ $t(item)}}</span>
          <div class="dropDownBox">
           <div class="titBox color1" >
              <div class="tit">{{ $store.state.language == 'en' ? 'English' : $store.state.language == "hk"? '繁體中文' : '简体中文' }}</div>
               <svg-btn class="icon" icon-class="icon-up-close"></svg-btn>
            </div>
            <div class="user2"></div>
            <div class="dropItem">
              <div class="one pointer" @click="changeLanguage(0)" >
                <div class="ontit">English</div>
              </div>
              <div class="one one2 pointer" @click="changeLanguage(1)" >
                <div class="ontit">简体中文</div>
              </div>
              <div class="one one2 pointer" @click="changeLanguage(2)">
                <div class="ontit">繁體中文</div>
              </div>
            </div>
           </div>
          <div class="nologin" v-if="!islogin">
            <span class="com-btn login-btn2" @click="goLogin()">{{ $t("home.Login") }}</span>
            <span class="com-btn register-btn2" @click="goLogin('register')">{{ $t("home.Signup") }}</span>
          </div>
          <div v-else class="userBox">
            <img class="avater" :src="userInfo.logo" width="28" height="28" />
            <span>{{ userInfo.nickname }}</span>
          </div>
          <!-- <div class="loginBox login2" @click="gocloud()">
            <span>{{ $t("home.Enterthewebdisk") }}</span>
            <img :src="$utils.getPng('web4/gocloud2')"/>
          </div> -->
        </div>
      </div>
      <div class="content">
        <div class="board">
          <div class="board-text" style="padding-right: 102px;">
            <h1>{{$t('home.BoardTitle1')}}</h1>
            <p>{{$t('home.BoardText1')}}</p>
            <div>
              <button v-if="!islogin" @click="goLogin('register')" class="fm-button" style="margin-right: 16px;">
                <span>{{$t('home.Signup')}}</span>
                <svg-btn icon-class="icon-right-arrow" />
              </button>
              <button v-else @click="gocloud()" class="fm-button" style="margin-right: 16px;">
                <span>{{$t('home.Enterthewebdisk')}}</span>
                <svg-btn icon-class="icon-right-arrow" />
              </button>
              <button class="button" @click="$refs.addScroll.scrollTo({top:9999,behavior: 'smooth'})">{{$t('home.Download')}}</button>
            </div>
          </div>
          <img :src="$utils.getPng('board1')" alt="">
        </div>
        <div class="board">
          <img :src="$utils.getPng('board2')" alt="">
          <div class="board-text" style="padding-left: 102px;">
            <h1>{{$t('home.BoardTitle2')}}</h1>
            <p>{{$t('home.BoardText2')}}</p>
              <button v-if="!islogin" @click="goLogin('register')" class="fm-button">
                <span>{{$t('home.Signup')}}</span>
                <svg-btn icon-class="icon-right-arrow" />
              </button>
              <button v-else @click="gocloud()" class="fm-button">
                <span>{{$t('home.Enterthewebdisk')}}</span>
                <svg-btn icon-class="icon-right-arrow" />
              </button>
          </div>
        </div>
        <!-- margin-bottom: 180px是临时代码，CloudMaster恢复之后就要把这个样式删掉 -->
        <div class="board" style="margin-bottom: 180px;">
          <div class="board-text" style="padding-right: 102px;">
            <h1>{{$t('home.BoardTitle3')}}</h1>
            <p>{{$t('home.BoardText3')}}</p>
            <button v-if="!islogin" @click="goLogin('register')" class="fm-button">
              <span>{{$t('home.Signup')}}</span>
              <svg-btn icon-class="icon-right-arrow" />
            </button>
            <button v-else @click="gocloud()" class="fm-button">
              <span>{{$t('home.Enterthewebdisk')}}</span>
              <svg-btn icon-class="icon-right-arrow" />
            </button>
          </div>
          <img :src="$utils.getPng('board3')" alt="">
        </div>
        <div v-if="false" class="CloudMaster" style="padding-top: 280px;">
          <h1>{{$t('home.CloudMaster')}}</h1>
          <p style="font-weight: 400;font-size: 16px;color: #8A8B92;line-height: 24px;margin-top: 4px;">{{$t('home.Sharing')}}</p>
          <div class="list">
            <div class="item">
              <div class="line">
                <div class="bar"></div>
              </div>
              <p>{{$t('home.Label1')}}</p>
              <span>{{$t('home.Text1')}}</span>
            </div>
            <div class="item">
              <div class="line">
                <div class="bar"></div>
              </div>
              <p>{{$t('home.Label2')}}</p>
              <span>{{$t('home.Text2')}}</span>
            </div>
            <div class="item">
              <div class="line">
                <div class="bar"></div>
              </div>
              <p>{{$t('home.Label3')}}</p>
              <span>{{$t('home.Text3')}}</span>
            </div>
            <div class="item">
              <div class="line">
                <div class="bar"></div>
              </div>
              <p>{{$t('home.Label4')}}</p>
              <span>{{$t('home.Text4')}}</span>
            </div>
          </div>
          <div class="more" @click="openDaren">
            <span>
              {{ $t('home.More') }}
            </span>
            <svg-btn icon-class="icon-right-arrow" />
          </div>
        </div>
        <div class="privilege">
          <h1>{{ $t("home.Privilege") }}</h1>
          <div class="card">
            <div class="list">
              <div v-for="(item, index) in privilegeList" class="item" :key="index">
                <svg-btn :icon-class="item" />
                <span>{{ $t(`home.CardText${index}`) }}</span>
              </div>
            </div>
          </div>
          <div class="more" @click="goRouter(2)">
            <span>
              {{ $t('home.MorePrivilege') }}
            </span>
            <svg-btn icon-class="icon-right-arrow" />
          </div>
        </div>
        <div class="comment-box">
          <span class="title">{{ $t("home.evaluate") }}</span>
          <div class="comment-body">
            <div style="width: 100%; overflow: hidden;height:298px;min-width:1200px">
              <div class="demobox1"></div>
              <div class="demobox2"></div>
              <vue-seamless-scroll :data="commentData" :class-option="optionSetting" class="picMarquee-left">
                <div class="bd">
                  <ul class="infoList">
                    <li v-for="(item, index) in commentData" :key="index">
                      <p class="desc">{{ item.desc }}</p>
                      <div class="info">
                        <img :src="item.img"/>
                        <span> {{ item.name }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </vue-seamless-scroll>
              <vue-seamless-scroll style="transform: translateX(-170px)" :data="commentData2" :class-option="optionSetting" class="picMarquee-left">
                <div class="bd">
                  <ul class="infoList">
                    <li v-for="(item, index) in commentData2" :key="index">
                      <p class="desc">{{ item.desc }}</p>
                      <div class="info">
                        <img :src="item.img"/>
                        <span> {{ item.name }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
        <div class="download-box">
          <div class="item" v-for="(item,index) in downloadData" :key="index">
            <div class="box">
                <img :src="$utils.getPng('web4/dl_icon'+item.id)"/>
                <span class="name">{{$t(item.name)}}</span>
                <span @click="onClike(item)" class="btn">{{ $t("home.DownloadNow") }}</span>
            </div>
            <div class="box2">
              <div class="imgBox">
                <img class="ewm" :src="$utils.getPng('downapp')" />
              </div>
              <div class="tit1">{{ $t("home.ScanQRcodedownload") }}</div>
            </div>
          </div>
        </div>
        <div class="cright">
          <span>Copyright © 2025 FEEMOO LIMITED</span>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import data3 from "../../utils/animation/avatar";
import {getHomeIndex ,clientDownUrlAPI, getUserInfoAPI,checkLoginFlag} from '@/utils/apiList/home'
export default {
  name: "firstPage",
  data() {
    return {
      navData: ["home.Home","home.Clouddrive","home.VIP"],
      privilegeList: ['icon-home-privilege0', 'icon-home-privilege1', 'icon-home-privilege2', 'icon-home-privilege3', 'icon-home-privilege4', 'icon-home-privilege5', 'icon-home-privilege6', 'icon-home-privilege7'],
      islogin:false,
      showNavbar2:false,
      downloadData:[
        {id:1,name:'home.AndroidClient'},
        {id:2,name:'home.iOSClient'},
        {id:3,name:'home.WindowsDesktop'},
        {id:4,name:'home.MacOSDesktop'},
      ],
      commentData:'',
      commentData2:'',
      userInfo: {},
      exe: {},
      fmpUrl:"https://wwwtest.feemoo.com" ,//这是飞猫盘海外版 用feemoo
    };
  },
  computed: {
    optionSetting() {
      return {
        step: 0.43, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100, // 单步运动停止的时间(默认值1000ms)
      };
    },
    language() {
      return this.$store.state.language;
    },
  },
  created() {
    clientDownUrlAPI().then((res) => {
      if(res.status=='1')this.exe = res.data;
    })
    this.checkLoginFun();
    this.getSubAvatar();
  },
  components: {
  },
  mounted(){
    document.addEventListener("visibilitychange", this.handleVisiable);
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.handleVisiable);
  },
  methods: {
    changeLanguage(index) {
      this.$utils.changeLanguage(this.$route,index)
      document.title = this.$t('common.title');
    },
     handleVisiable(e) {
      if(e.target.visibilityState==='visible'&&!this.islogin) this.checkLoginFun();
    },
    openDaren() {
      const url = process.env.VUE_APP_DAREN_URL
      window.open(url)
    },
    scrollBox(event) {
      this.showNavbar2= event.target.scrollTop>0
    },
    getSubAvatar(){
      getHomeIndex({type:'internal'})
        .then((res) => {
          if(res.status=='1'){
            var commentList=res.data.iconList//评论
            this.commentData= commentList.slice(0, 4)
            this.commentData2= commentList.slice(4, 8)
            let arr = data3.assets
            for(var i=0;i<arr.length;i++){
              for(var j=0;j<res.data.logoInfo.length;j++){
                if(i==j){
                  arr[i].p = res.data.logoInfo[j].name
                  arr[i].u = res.data.logoInfo[j].path
                }
              }
            }
            data3.assets=arr
          }else{

          }
        })
        .catch((err) => {
        });
    },
    goLogin(type) {
      const query = {
        path: this.$store.state.fmPath
      }
      if (type === 'register') {
        query.tab = '2'
      }
      // this.$router.push({ path: "/login", query });
      const lang = {
        en: '/en-US',
        hk: '/zh-HK',
        cn: ''
      }      
      window.location.href = `${this.fmpUrl}${lang[this.$store.state.language]}/login?path=${query.path}${type == 'register' ?'&tab=2' : ''}`
    },
    gocloud(){
      this.$router.push({ path: '/drive' });
    },
    goRouter(index){
      const lang = {
        en: '/en-US',
        hk: '/zh-HK',
        cn: ''
      }
      if(index==0){
        window.location.reload();
      } else if(index==1){
        if(this.islogin){
          this.$router.push({ path: this.$store.state.fmPath });
        }else {
          window.location.href = `${this.fmpUrl}${lang[this.$store.state.language]}/login?path=drive`
        }
      } else{
        if(this.islogin){
          this.$utils.routeModeReported('B8');
        }
        window.open(`${this.fmpUrl}${lang[this.$store.state.language]}/vip`,"_blank")
      }
    },
    onClike(item) {
      if (item.id == 3) {
        // 创建链接元素
        var link = document.createElement("a");
        link.href = this.exe.win_url;
        link.download = "";
        // 模拟点击链接以下载文件
        link.click();
      }else if(item.id == 4){
        // 创建链接元素
        var link = document.createElement("a");
        link.href = this.exe.mac_url;
        link.download = "";
        // 模拟点击链接以下载文件
        link.click();
      }
    },
    checkLoginFun: _.debounce(
      function () {
       checkLoginFlag({}).then((res) => {
        if(res.status=='1'){
          if(res.data.status =='1'){
            getUserInfoAPI().then(res => {
              this.islogin = true
              this.userInfo = res.data
            })
          }else {
            this.$utils.clearCookie(this.$glb.fmCookieName);
            this.islogin = false;
          }
        }
        })
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
  }
};
</script>
<style lang="scss" scoped>
@import "./index.scss";

#homepage{
  .userBox {
    display: flex;
    align-items: center;
    margin-left: 28px;
    .avater {
      border-radius: 14px;
      margin-right: 12px;
    }
  }
  h1{ 
    font-size: 40px;
    font-weight: 600;
    color: #2C2C2C;
    line-height: 60px;
    margin: 0;
  }
  .board {
    width: 1200px;
    margin: 152px auto 0;
    display: flex;
    align-items: center;
    .board-text {
      flex: auto;
    }
    h1 {
      line-height: 56px;
      margin-bottom: 24px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      white-space: pre-line;
      color: #8A8B92;
      line-height: 24px;
      margin-bottom: 32px;
    }
    img {
      height: 400px;
    }
  }
  .CloudMaster {
    text-align: center;
    padding: 180px 0;
    .list {
      width: 1140px;
      min-height: 192px;
      margin: 64px auto 52px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .item {
        width: 252px;
        text-align: left;
        .line {
          height: 1px;
          overflow: visible;
          background: #E5E5E5;
          margin-bottom: 24px;
          .bar {
            height: 2px;
            width: 24px;
            background: #F67942;
          }
        }
        p {
          font-weight: 500;
          font-size: 16px;
          color: #2C2C2C;
          line-height: 22px;
        }
        span {
          margin-top: 15px;
          font-weight: 400;
          font-size: 16px;
          color: #8A8B92;
          white-space: pre-line;
          line-height: 24px;
        }
      }
    }
  }
  .privilege {
    height: 1092px;
    padding: 180px 0;
    background: #F9F9F9;
    text-align: center;
    box-sizing: border-box;
    .card {
      margin: 64px auto;
      width: 1140px;
      height: 522px;
      box-sizing: border-box;
      background: #FFFFFF;
      border-radius: 40px;
      padding: 100px 70px 0;
      .list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
      }
      .item {
        width: 210px;
        height: 154px;
        text-align: center;
        margin: 0 20px 38px;
        .svg-icon {
          height: 92px;
          width: 92px;
        }
        span {
          display: block;
          white-space: pre-line;
          margin: auto;
          font-weight: 500;
          font-size: 18px;
          color: #2C2C2C;
          line-height: 26px;
        }
      }
    }
    
  }
  .more {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: #F67942;
    line-height: 22px;
    .svg-icon {
      margin-left: 16px;
      width: 14px;
      height: 14px;
    }
  }
  .button {
    cursor: pointer;
    height: 52px;
    line-height: 52px;
    padding: 0 32px;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    font-weight: 500;
    font-size: 18px;
    background-color: #fff;
    
  }
  .button:hover {
    background: #F7F7F7;
  }
  .fm-button {
    font-weight: 500;
    font-size: 18px;
    padding: 0 32px;
    color: #fff;
    height: 52px;
    display: inline-flex;
    align-items: center;
    background: #F67942;
    border-radius: 12px;
    .svg-icon {
      margin-left: 8px;
      width: 14px;
      height: 14px;
    }
  }
  .fm-button:hover {
    background: #FAAF8E;
  }
  .fm-button:active {
    background: #D84F13;
  }
}


</style>
